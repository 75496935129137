import React, { Fragment, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
  colors,
  Button,
  ButtonGroup,
  ClickAwayListener,
  FormHelperText,
  Grow,
  InputAdornment,
  MenuItem,
  MenuList,
  Popper,
  Paper,
  TextField,
  IconButton,
  Typography
} from '@material-ui/core';
import {
  ArrowDropDown as ArrowDropDownIcon,
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon
} from '@material-ui/icons';
import { updateObjectSelected } from 'redux/object/ObjectAction';
import { DEFAULT_REVISION_TYPE, REVISION_LABEL } from 'common/constants';
import { getRevisionDisplay, getRevisionInfo } from '../helper';
import { useStyles } from '../../styles';
import classNames from 'classnames';

const RevisionPopover = props => {
  const {
    disableEdit,
    displayObjectView,
    isForward,
    isRevision,
    isViewOpen,
    revisions,
    selectedRevision,
    setFormState
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const anchorListRef = useRef(null);

  const currRevision = revisions.find(
    rev => rev.metadata.revision === selectedRevision
  );
  const displayRevision = currRevision?.metadata?.revision_sequence_display;
  const revisionDisplay = getRevisionDisplay(
    selectedRevision,
    displayRevision,
    true
  );

  const initState = {
    editRev: false,
    error: false,
    newRev: null,
    openList: false
  };
  const [state, setState] = useState(initState);

  const handleRevisionUpdate = () => {
    const revisionType = currRevision
      ? currRevision.metadata.selected_category?.revision_type?.action_name
      : revisions[0].metadata?.selected_category?.revision_type?.action_name;

    const { revision, revision_sequence_display } = getRevisionInfo(
      revisionType ?? DEFAULT_REVISION_TYPE,
      state.newRev,
      selectedRevision,
      revisions?.at(-2)?.metadata?.revision
    );

    if (revision == null)
      return setState(prevState => ({ ...prevState, error: true }));

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        metadata: {
          ...formState.values.metadata,
          revision,
          revision_sequence_display
        }
      },
      selectedRevision: revision
    }));

    currRevision.metadata = {
      ...currRevision.metadata,
      revision,
      revision_sequence_display
    };
    setState(initState);
  };

  const handleChangeRevision = (event, newRev) => {
    if (newRev === selectedRevision) handleClosePopper(event);
    else {
      setFormState(formState => ({
        ...formState,
        selectedRevision: newRev,
        revisionChanged: true
      }));

      const revObj = revisions.find(rev => rev.metadata.revision === newRev);
      if (isViewOpen) displayObjectView(revObj.id, revObj.object_type.ref_name);
      dispatch(updateObjectSelected(revObj));

      handleListPopper();
    }
  };

  const handleListPopper = () =>
    setState(prevState => ({ ...prevState, openList: !prevState.openList }));

  const handleClosePopper = event => {
    if (anchorListRef.current && anchorListRef.current.contains(event.target))
      return;
    setState(prevState => ({ ...prevState, openList: false }));
  };

  if (
    (!isRevision &&
      !(selectedRevision > -1 && revisions && revisions.length > 1)) ||
    isForward
  )
    return null;

  return state.editRev ? (
    <div className={classes.displayFlex}>
      <TextField
        InputProps={{
          classes: {
            root: state.error ? classes.revisionErrorText : classes.white
          },
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end" style={{ marginLeft: 0 }}>
              <IconButton
                className={classNames(classes.addItemIcon, classes.noPadding)}
                disabled={!state.newRev}
                onClick={handleRevisionUpdate}
                size="small">
                <CheckCircleIcon fontSize="small" />
              </IconButton>
              <IconButton
                className={classNames(classes.delItemIcon, classes.noPadding)}
                onClick={() => setState(initState)}
                size="small">
                <CancelIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
          startAdornment: (
            <InputAdornment position="start" style={{ marginRight: 2 }}>
              <Typography className={classes.revisionLabel}>
                {REVISION_LABEL}
              </Typography>
            </InputAdornment>
          )
        }}
        defaultValue={revisionDisplay.replace(REVISION_LABEL, '')}
        inputProps={{
          maxLength: 4,
          style: {
            borderBottom: '1px solid',
            borderColor: state.error ? colors.red[600] : colors.white,
            fontWeight: 500,
            margin: 0,
            padding: 0,
            textTransform: 'uppercase'
          }
        }}
        onChange={event =>
          setState(prevState => ({
            ...prevState,
            newRev: event.target.value
          }))
        }
        size="small"
        style={{ maxWidth: '116px' }}
      />
      {state.error ? (
        <FormHelperText className={classes.marginLeft1} error>
          <FormattedMessage
            defaultMessage="Invalid revision"
            id="error.INVALID_REVISION"
          />
        </FormHelperText>
      ) : null}
    </div>
  ) : (
    <Fragment>
      <ButtonGroup
        disableElevation
        ref={anchorListRef}
        size="small"
        style={{ marginLeft: 8 }}
        variant="contained">
        <Button
          className={classes.revisionButton}
          classes={
            disableEdit ? null : { label: classes.revisionButtonUnderline }
          }
          disabled={disableEdit}
          onClick={() =>
            setState(prevState => ({ ...prevState, editRev: true }))
          }
          size="small"
          variant="text">
          {revisionDisplay}
        </Button>
        {isRevision ? null : (
          <Button
            className={classes.revisionButton}
            onClick={handleListPopper}
            size="small">
            <ArrowDropDownIcon fontSize="small" />
          </Button>
        )}
      </ButtonGroup>
      <Popper
        anchorEl={anchorListRef.current}
        disablePortal
        open={state.openList}
        placement="bottom-end"
        style={{ zIndex: 1 }}
        transition>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClosePopper}>
                <MenuList autoFocusItem>
                  {[...revisions].reverse().map(rev => (
                    <MenuItem
                      key={rev.id}
                      onClick={e =>
                        handleChangeRevision(e, rev.metadata.revision)
                      }>
                      {rev.metadata.revision_sequence_display}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

RevisionPopover.propTypes = {
  disableEdit: PropTypes.bool,
  displayObjectView: PropTypes.func,
  isForward: PropTypes.bool,
  isRevision: PropTypes.bool,
  isViewOpen: PropTypes.bool,
  revisions: PropTypes.array,
  selectedRevision: PropTypes.any,
  setFormState: PropTypes.func
};

export default RevisionPopover;
