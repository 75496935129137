import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';
import {
  ArrowBack as ArrowBackIcon,
  Delete as DeleteIcon,
  Edit as EditIcon
} from '@material-ui/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import QueryString from 'query-string';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Slide,
  Snackbar,
  TextField,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  Attachment,
  Boq as ObjectBoq,
  CategoryInfo,
  Documentation as ObjectDocument,
  DocumentFlow,
  DocUploader,
  Eo as ObjectEo,
  History,
  Ico as ObjectIco,
  Koe as ObjectKoe,
  Letter as ObjectLetter,
  // LinkedObject,
  Meeting as ObjectMeeting,
  MorePopover,
  OverrideAlert,
  RevisionPopover,
  Status,
  Ta as ObjectTa
} from '../components';
import {
  BreadCrumbs,
  Confirm,
  Delete,
  NoBgCardAction,
  NoButtonPopup,
  Popup
} from 'components';
import {
  clearDocumentMessages,
  createOrUpdateDocumentCategory,
  deleteDocumentCategory,
  deleteWithRoute,
  disciplineTypeFetch,
  fetchProjectCategories,
  objectCreateOrUpdate,
  objectsFetch,
  objectStatusFetch,
  objectTypeFetch,
  orgMeetingTemplatesFetch,
  revisionFetch,
  revisionTypesFetch,
  setSelectedProjectId,
  singleObjectFetch,
  singleProjectFetch
} from '../../../redux';
import {
  convertLettersToNum,
  deleteUnsavedAttachments,
  filterObjTypesList,
  formatNumberDisplay,
  getCategoryInfo,
  getCurrentOrgType,
  getDisplayName,
  getDisplayPostnr,
  getNextObjectRevision,
  getNewAgendaFromTemplate,
  getObjectCreator,
  getObjectName,
  getObjectTypeById,
  getObjectTypeByRefName,
  getOrganizationCode,
  getOrganizationName,
  getPreviousObjectRevision,
  getRecipientList,
  getReloadParams,
  getStatusById,
  getStatusByName,
  getStatusIdByName,
  getTopAccessLevel,
  getUploadPath,
  getUserDisciplines,
  isFromSenderOrg,
  processPdfDownloadParams,
  setRefUsers,
  getExistingCategoryNames
} from '../components/helper';
import {
  formatCategories,
  isCurrentOrg,
  isObjectEmpty,
  serializeParams,
  valueInArray
} from 'common/helper';
import { useFetch, useObjectInfo } from 'hooks';
import { useStyles } from '../styles';
import { useStyles as compStyles } from 'components/styles';
import _ from 'lodash';
import {
  evaluateStatus,
  getDefaultRevisionStatus,
  isCompletedStatus,
  isSentStatus
} from '../components/statusProcessor.js';
import {
  validateBoQObject,
  validateDocObject,
  validateExecObject,
  validateDocumentCategory
} from '../validations/validator';
import {
  ADD,
  ANCESTRY,
  ATTACHMENTS,
  BOQ,
  CATEGORIES,
  CATEGORY,
  CHECKBOX,
  DATA_TYPE_BOOLEAN,
  DEFAULT,
  DEFAULT_MEETING_NO,
  DEFAULT_REVISION_TYPE,
  DELETE,
  DISCIPLINE_TYPE_ID,
  DIVIDER,
  DOCUMENTATION,
  DOCUMENTATION_TYPE_MULTIPLE,
  DOT,
  EDIT,
  FORWARD_SLASH,
  HISTORY_POP,
  HISTORY_REPLACE,
  HYPHEN,
  ID,
  IMPACT,
  IMPACT_PRICE,
  IMPACT_SCHEDULE,
  LATEST_MOM_NUMBER_VERSION,
  LS_ORGANIZATION_ID,
  LS_ORGANIZATION_NAME,
  NAME,
  NEW,
  NEW_COMMENT,
  NEW_COMMENT_TEXT,
  OBJECT,
  OBJECT_ALL,
  OBJECT_BHM,
  OBJECT_BOQ,
  OBJECT_DOC,
  OBJECT_EO,
  OBJECT_ICO,
  OBJECT_KOE,
  OBJECT_REF,
  OBJECT_TA,
  OBJECT_TMPL,
  OBJECTS,
  OBJECTS_ALL,
  ORGANIZATION_MEETING_TEMPLATE,
  REVISION,
  REVISION_VALUES,
  ROLE_PL,
  SELECTED_CATEGORY,
  SS_SELECTED_CATEGORY,
  SS_STORED_PROJECT_ID,
  STATUS_APPROVED,
  STATUS_CLAIM_SENT,
  STATUS_DRAFT,
  STATUS_FINAL_QUANTITIES_ISSUED,
  STATUS_ISSUED_FOR_APPROVAL,
  STATUS_NOT_USED,
  STATUS_NOTIFIED,
  STATUS_OPEN,
  STATUS_PUBLISHED,
  STATUS_READ,
  STATUS_REVISED,
  STATUS_UPDATED_QUANTITIES_ISSUED,
  TEMPLATE,
  TITLE,
  USER,
  VALUES
} from 'common/constants';
import {
  LBL_CREATE_NEW_DOC_OBJECT,
  LBL_CREATE_NEW_EXEC_OBJECT,
  LBL_SELECT_DISCIPLINE_TYPE,
  LBL_SELECT_RECIPIENT
} from '../components/labels';
import { generate } from 'redux/pdf/PdfAction';
import classNames from 'classnames';

const AUTOSAVE_INTERVAL = 6000;
const components = {
  bhm: ObjectTa,
  boq: ObjectBoq,
  eo: ObjectEo,
  ico: ObjectIco,
  koe: ObjectKoe,
  le: ObjectLetter,
  ref: ObjectMeeting,
  ta: ObjectTa,
  default: null
};
const ObjectInfo = props => {
  const classes = useStyles();
  const compClasses = compStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { history, location, match } = props;
  const {
    document: {
      categories: documentCategories,
      category,
      categoryDeleted,
      categoryError
    },
    object: {
      currObjDeleted,
      disciplineTypes: objectDisciplineTypes,
      error: objectError,
      fetchError,
      infoRevisions: objectRevisions,
      loading: objectLoading,
      objectsAll: { content: objectsAll = [] },
      objectSelected,
      revisionTypes = [],
      status: objectStatus,
      statusAll: objectStatusAll,
      templateId: objectTemplateId,
      templateUpdated,
      types: objectTypes,
      updated: objectUpdated,
      updatedObjFromConflict
    },
    object,
    project: { projectSelected },
    user
  } = useSelector(state => state);
  const orgId = localStorage.getItem(LS_ORGANIZATION_ID);
  const query = QueryString.parse(location.search);
  const projectId =
    query.project_id ||
    location.state?.projectId ||
    sessionStorage.getItem(SS_STORED_PROJECT_ID);

  // commenting this for now since we'll be supporting multiple orgs per user in the future
  // force to organization list or project list if missing data
  // if (!orgId) history.push('/organizations');
  if (!orgId || !projectId) history.push('/projects');
  if (fetchError) {
    setSelectedProjectId(projectId);
    history.push('/objects');
  }

  const initState = {
    isConflictPopupOpen: false,
    isValid: false,
    isSaving: false,
    isForward: false,
    isDoc: false,
    isReloadOnly: false,
    isRevision: false,
    revisionChanged: false,
    checkedBoxes: {},
    errors: {},
    prevValues: { title: '', project_id: projectId, metadata: {} },
    revisionValues: {},
    timer: {},
    values: { title: '', project_id: projectId, metadata: {} },
    categories: [],
    deletedAttachments: [],
    mentionedObjs: [],
    mentionedUsers: [],
    selectedRevision: -1,
    showBoQReopen: false
  };
  const initCategoryState = { action: null, category: null, errors: {} };
  const [formState, setFormState] = useState(initState);
  const [confirm, setConfirm] = useState(null);
  const [hasPopup, setHasPopup] = useState(false);
  const [hasDiscAccess, setDiscAccess] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const [tmplError, setTmplError] = useState(false);
  const [fromRevisedObj, setFromRevisedObj] = useState(false);
  const [categoryManagement, setCategoryManagement] = useState(
    initCategoryState
  );
  const [showCategoryInfo, setShowCategoryInfo] = useState(false);
  const [viewState, setViewState] = useState({
    index: 0,
    objectId: null
  });
  const {
    categories,
    errors: fsErrors,
    isDoc,
    isForward,
    isReloadOnly,
    isRevision,
    isSaving,
    isSavingTemplate,
    isValid,
    revisionChanged,
    revisionValues,
    selectedRevision,
    showBoQReopen,
    values
  } = formState;

  const fsValuesField = isRevision ? REVISION_VALUES : VALUES;
  const isNew = match.params.id === NEW.toLowerCase() ? true : false;
  const topAccessLevel = getTopAccessLevel(projectSelected.roles);
  const selectedObjType = getObjectTypeById(objectTypes, values.object_type_id);
  const refName = selectedObjType?.ref_name || DEFAULT;
  const isBoQ = refName === OBJECT_BOQ;
  const isMeeting = refName === OBJECT_REF;
  const TagName = isDoc ? ObjectDocument : components[refName.toLowerCase()];
  const hasNewMomNumbering =
    projectSelected?.settings?.mom_number_version === LATEST_MOM_NUMBER_VERSION;

  //////////////////////////////////////////////////////////////////////////////
  //                      START OF USEEFFECT FUNCTIONS                        //
  //////////////////////////////////////////////////////////////////////////////

  useObjectInfo(props, !revisionChanged);
  useFetch(objectTypeFetch, objectTypes);

  useEffect(() => {
    if (projectSelected.archived) history.push('/objects');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectSelected]);

  useEffect(() => {
    const state = location.state || {};
    setFormState(formState => {
      const metadata = state.boqId
        ? {
            boq_id: state.boqId,
            created_by_org_id: state.created_by_org_id,
            ...state.boq
          }
        : {};
      const values = {
        ...formState.values,
        object_type: state.objectType ?? formState.values.object_type,
        object_type_id: state.objectType
          ? state.objectType.id
          : formState.revisionChanged
          ? formState.values.object_type_id
          : '',
        discipline_type_id:
          formState.values.discipline_type_id || state.disciplineTypeId,
        projectId: state.projectId || '',
        template_id: state.templateId || '',
        title: formState.values.title || state.title || '',
        metadata: {
          ...metadata,
          ...formState.values.metadata
        },
        new_comment_text: state.new_comment_text || ''
      };

      if (state.selectedTemplate?.source === ORGANIZATION_MEETING_TEMPLATE) {
        values.title = state.selectedTemplate.title;
        values.agenda = state.selectedTemplate.agenda.map((agendum, index) =>
          getNewAgendaFromTemplate(
            agendum,
            hasNewMomNumbering
              ? formatNumberDisplay(DEFAULT_MEETING_NO)
                  .concat(DOT)
                  .concat(formatNumberDisplay(index + 1))
              : null
          )
        );
        values.linked_objects = [];
        values.linked_users = [];
        values.metadata.global_template_id = state.templateId;
        values.is_global_template = true;
      }

      return {
        ...formState,
        values: values,
        prevValues: _.cloneDeep(values)
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (objectDisciplineTypes.length === 0)
      dispatch(disciplineTypeFetch(projectId));
    if (objectStatusAll.length === 0)
      dispatch(objectStatusFetch({ type: OBJECT }));
    if (revisionTypes.length === 0) dispatch(revisionTypesFetch());
    if (isObjectEmpty(projectSelected) || projectSelected.id !== projectId)
      dispatch(
        singleProjectFetch(
          projectId,
          serializeParams({ organization_id: orgId })
        )
      );

    dispatch(
      objectsFetch(
        projectId,
        serializeParams({ type: OBJECT_ALL }),
        OBJECTS_ALL
      )
    );

    const pathArr = match.path.split(FORWARD_SLASH);
    const isPathDoc = valueInArray(pathArr, OBJECT_DOC.toLowerCase());
    if (isPathDoc) dispatch(fetchProjectCategories(CATEGORIES, projectId));
    if (isNew) {
      setFormState(formState => ({
        ...formState,
        isDoc: isPathDoc
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (refName === OBJECT_REF) {
      dispatch(orgMeetingTemplatesFetch(orgId));
      dispatch(objectsFetch(projectId, serializeParams({ type: TEMPLATE })));
    }
  }, [orgId, projectId, refName, dispatch]);

  useEffect(() => {
    if (documentCategories)
      setFormState(fs => ({
        ...fs,
        categories: formatCategories(documentCategories)
      }));
  }, [documentCategories]);

  useEffect(() => {
    if (!isNew && match.params.id && projectId && objectRevisions.length === 0)
      dispatch(revisionFetch(match.params.id, `project_id=${projectId}`));
  }, [dispatch, isNew, match.params.id, objectRevisions, projectId]);

  useEffect(() => {
    if (currObjDeleted) {
      if (objectRevisions.length > 1) {
        const prevRevId = objectRevisions[selectedRevNo - 1].id;
        if (viewState.objectId)
          setViewState(viewState => ({ ...viewState, objectId: prevRevId }));
        dispatch(singleObjectFetch(prevRevId, false));
      } else history.push('/objects');
    } else if (isObjectEmpty(values.status))
      setFormState(formState => {
        let status = objectStatus.find(s => s.name === STATUS_DRAFT);
        status = {
          status,
          status_id: status?.id || formState.values.status_id
        };
        return {
          ...formState,
          values: { ...formState.values, ...status },
          prevValues: { ...formState.prevValues, ...status }
        };
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currObjDeleted, history, objectStatus]);

  useEffect(() => {
    if (!objectLoading) {
      setFormState(formState => ({
        ...formState,
        isSaving: false,
        isSavingTemplate: false
      }));
    }
  }, [objectLoading]);

  useEffect(() => {
    const { id: objId, object_type } = values;
    const { pathname, search, state } = location;
    const pathUuid = pathname?.slice(pathname.lastIndexOf(FORWARD_SLASH) + 1);
    const diffId =
      pathUuid &&
      pathUuid !== objId &&
      (revisionChanged ||
        (!isObjectEmpty(objectSelected) && objectSelected.id !== objId));

    // reset mentions list when page is loaded with another object
    if (pathUuid !== objId && !revisionChanged)
      setFormState(formState => ({
        ...formState,
        mentionedObjs: [],
        mentionedUsers: []
      }));

    if (
      objId &&
      state &&
      objId !== state.templateId &&
      (isForward || isNew || diffId)
    ) {
      if (state.templateId) {
        delete location.state.selectedTemplate;
        delete location.state.templateId;
      }
      let path = state.isDoc ? OBJECT_DOC.toLowerCase() : OBJECT;
      history.replace(`/${path}/${objId}`.concat(search), state);
    }

    if (object_type?.ref_name) {
      const category =
        object_type.ref_name === OBJECT_BOQ
          ? BOQ
          : object_type.ref_name === OBJECT_DOC
          ? DOCUMENTATION
          : OBJECT;
      sessionStorage.setItem(SS_SELECTED_CATEGORY, category);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.id, history, location.state, match, isNew]);

  useEffect(() => {
    const { object_type_id, status } = isRevision ? revisionValues : values;
    if (object_type_id) {
      const statusParam = status?.id ? `&status_id=${status.id}` : '';
      const params = `type_id=${object_type_id}${statusParam}`;
      dispatch(objectStatusFetch({ type: OBJECT, params: params }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.object_type_id, values.status, revisionValues.status, dispatch]);

  useEffect(() => {
    if (!isObjectEmpty(objectSelected) && isLoaded) {
      if (!objectSelected.object_type) {
        setTmplError(!!objectSelected.template_error);
        const { metadata, name, status_id } = values;
        const currStatus = getStatusById(objectStatus, status_id);
        if (
          objectUpdated &&
          name &&
          name.includes(`${OBJECT_DOC}-`) &&
          valueInArray(
            [STATUS_PUBLISHED, STATUS_ISSUED_FOR_APPROVAL],
            currStatus.name
          ) &&
          metadata.documentation_type.name === DOCUMENTATION_TYPE_MULTIPLE &&
          !metadata.isMultiDocSeparated
        )
          history.push('/objects');
        else {
          //update revision only when there were no errors
          setFormState(formState => ({
            ...formState,
            isRevision: false,
            revisionChanged: true
          }));
          dispatch(singleObjectFetch(objectSelected.id, objectUpdated));
        }
      } else {
        const obj = objectSelected;
        const { agenda: agendaObj, metadata, object_type, object_users } = obj;

        setFormState(formState => {
          const {
            metadata: fsMetadata,
            new_comment: fsNewComment,
            new_comment_text: fsNewCommentText,
            object_type: fsObjectType,
            status_id: fsStatusId
          } = formState.values;
          const objAttributeVal = metadata || fsMetadata;
          const agenda = agendaObj || [];
          const objUsers = object_users || [];
          const disciplineId = obj.discipline_type?.id;
          const recipientId = obj.organization?.id || disciplineId;
          let objType = obj.object_type || fsObjectType;

          if (objType.ref_name === OBJECT_TMPL) {
            objType = getObjectTypeByRefName(objectTypes, OBJECT_REF);
            objAttributeVal.template_id = obj.id;
            obj.id = null;
            // https://trello.com/c/VDtB3pTP/508-system-ux-iteration#comment-6180e7bd8e58be7869bfcef8
            obj.attachments = [];
          }

          const clearComment = objectUpdated && !formState.reloadComment;
          const objValues = {
            ...formState.values,
            ...obj,
            id: obj.id,
            object_type_id: objType?.id,
            discipline_type_id: recipientId,
            disc_type_id: disciplineId,
            status: obj.status,
            status_id: obj.status?.id || fsStatusId,
            main_status: false,
            discipline_status: false,
            updated_disciplines: null,
            agenda: _.cloneDeep(agenda),
            metadata: _.cloneDeep(objAttributeVal),
            object_users: _.cloneDeep(objUsers),
            comments: obj.comments,
            new_comment: clearComment ? null : fsNewComment,
            new_comment_text: clearComment ? '' : fsNewCommentText,
            // get latest revise policy, otherwise it might retain the old value
            // since it is only passed for Doc type with Published status
            access_policy_revise: obj.access_policy_revise,
            access_policy_delete: obj.access_policy_delete
          };

          setFromRevisedObj(!!objAttributeVal.updated_from_revised);

          return {
            ...formState,
            errors: {},
            values: objValues,
            prevValues: _.cloneDeep(objValues),
            isDoc: object_type.ref_name === OBJECT_DOC,
            mentionedObjs: formState.mentionedObjs,
            mentionedUsers: formState.mentionedUsers,
            reloadComment: false,
            selectedRevision:
              objAttributeVal.revision > -1 || isNaN(objAttributeVal.revision)
                ? objAttributeVal.revision
                : formState.selectedRevision
          };
        });
      }
    } else {
      if (!isLoaded) setLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectSelected, dispatch]);

  useEffect(() => {
    const unblock = history.block((hist, action) => {
      if (action === HISTORY_POP && isFormUpdated()) {
        setConfirm({
          onConfirm: () => exitPage(hist.pathname, hist.state)
        });
        return false;
      }
      return true;
    });
    return () => unblock();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, revisionValues, values]);

  useEffect(() => {
    if (
      !hasPopup &&
      (!disableEdit ||
        statusEdit ||
        (refName === OBJECT_ICO && hasDiscAccess) ||
        access_policy_revise ||
        access_policy_distribution)
    ) {
      const errors = formState.statusChange
        ? fsErrors
        : isDoc
        ? validateDocObject(objectStatus, values)
        : isBoQ
        ? validateBoQObject(objectStatus, formState[fsValuesField])
        : validateExecObject(objectStatus, formState, refName);

      const valid = isObjectEmpty(errors);
      setFormState(formState => ({
        ...formState,
        isValid: valid,
        errors: errors || {},
        statusChange: false
      }));

      //hook for autosave
      formState.timer = setTimeout(() => {
        if (
          !disableEdit &&
          isFormUpdated() &&
          valid &&
          !isDoc &&
          !isBoQ &&
          !isForward &&
          !updatedObjFromConflict
        )
          handleSubmit();
      }, AUTOSAVE_INTERVAL);
      return () => clearTimeout(formState.timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revisionValues, values, hasPopup]);

  useEffect(() => {
    if (category?.id) {
      dispatch(fetchProjectCategories(CATEGORIES, projectId));
      resetCategoryManagement();
      handleCategoryChange(category);

      const current_category = metadata?.selected_category;
      if (
        category.id === current_category?.id ||
        category.revision_type_id !== current_category?.revision_type_id
      )
        dispatch(revisionFetch(objId, `project_id=${projectId}`));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, projectId, dispatch]);

  useEffect(() => {
    setShowCategoryInfo(
      !!categoryManagement?.category && categoryManagement.action !== DELETE
    );
  }, [categoryManagement]);

  useEffect(() => {
    if (categoryDeleted) {
      dispatch(fetchProjectCategories(CATEGORIES, projectId));
      if (selected_category.id === categoryManagement.category?.id)
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            metadata: { ...formState.values.metadata, selected_category: null }
          }
        }));

      resetCategoryManagement();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryDeleted]);

  useEffect(() => {
    if (objectUpdated && isDoc && !!categoryError)
      dispatch(clearDocumentMessages());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectUpdated]);

  useEffect(() => {
    if (templateUpdated) {
      if (!metadata.template_id && objectTemplateId)
        handleSubmit(null, {
          ...values,
          agenda: values.agenda,
          metadata: {
            ...metadata,
            ...(hasNewMomNumbering
              ? {
                  meeting_no: DEFAULT_MEETING_NO,
                  previous_meeting_item_count: 0
                }
              : {}),
            template_id: objectTemplateId,
            original: true
          }
        });

      dispatch(objectsFetch(projectId, serializeParams({ type: TEMPLATE })));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateUpdated]);

  // Create template on navigate from NewObject IF global template is selected
  useEffect(() => {
    if (values.is_global_template) handleTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.is_global_template]);

  //////////////////////////////////////////////////////////////////////////////
  //                        END OF USEEFFECT FUNCTIONS                        //
  //                         START OF LOCAL FUNCTIONS                         //
  //////////////////////////////////////////////////////////////////////////////

  const isFormUpdated = () => {
    return !_.isEqual(formState.prevValues, formState[fsValuesField]);
  };

  const clearTimer = () => clearTimeout(formState.timer);

  const displayObjectView = (id, objType) => {
    setViewState(viewState => ({
      ...viewState,
      index: 0,
      objectId: id,
      objectType: objType?.toLowerCase() || ''
    }));
  };

  const handleAttachmentClick = index => {
    setViewState(viewState => ({
      ...viewState,
      index,
      objectId: null
    }));
  };

  const handleCategoryChange = value => {
    let categoryPath = { path: '', root: '' },
      docObjType = '',
      objectType = '',
      newValue = value;
    //create new category
    if (value) {
      docObjType = OBJECT_DOC;
      objectType = getObjectTypeByRefName(objectTypes, docObjType);
      categoryPath = getCategoryInfo(categories, value);
    }

    setFormState(formState => {
      let metadata = {
        ...formState.values.metadata,
        [SELECTED_CATEGORY]: newValue,
        selected_category_path: categoryPath
      };

      return {
        ...formState,
        values: {
          ...formState.values,
          object_type_id: objectType?.id || '',
          metadata
        }
      };
    });
  };

  const handleChange = event => {
    event.persist();
    setFormState(formState => {
      const { name, value } = event.target;
      let values = {
        ...formState.values,
        [name]: value
      };
      if (name === DISCIPLINE_TYPE_ID) values.disc_type_id = null;

      return {
        ...formState,
        values: values,
        prevValues:
          name === DISCIPLINE_TYPE_ID &&
          !formState.prevValues[name] &&
          !formState.isForward
            ? {
                ...formState.prevValues,
                [name]: value,
                disc_type_id: null
              }
            : { ...formState.prevValues }
      };
    });
  };

  const handleMetadataChange = event => {
    event.persist();
    const { checked, name, type, value } = event.target;
    let newMetadata = {
      ...formState[fsValuesField].metadata,
      [name]: type === CHECKBOX ? checked : value
    };
    if (!value && !checked && !(name in formState.prevValues.metadata))
      delete newMetadata[name];

    setFormState(formState => ({
      ...formState,
      [fsValuesField]: {
        ...formState[fsValuesField],
        metadata: newMetadata
      }
    }));
  };

  const handleCbChange = (event, name, vals) => {
    event.persist();

    const arr = vals[name];
    const { checked, name: eName } = event.target;
    if (checked) arr.push(eName);
    else arr.splice(arr.indexOf(eName), 1);

    let newMetadata = {
      ...formState[fsValuesField].metadata,
      [name]: arr
    };
    if (name === IMPACT && eName === IMPACT_PRICE.toLowerCase()) {
      newMetadata = { ...newMetadata, hasCostEffects: checked };

      if (!checked) {
        delete newMetadata.impact_price_description;
        delete newMetadata.total_cost_nok;
      }
    }
    if (
      !checked &&
      name === IMPACT &&
      eName === IMPACT_SCHEDULE.toLowerCase()
    ) {
      delete newMetadata.impact_total_schedule_days;
    }

    setFormState(formState => ({
      ...formState,
      [fsValuesField]: {
        ...formState[fsValuesField],
        metadata: newMetadata
      }
    }));
  };

  const handleMentionItems = (type, vals, concat) => {
    setFormState(formState => {
      let mentionedItems = formState[type] || [];

      if (concat) {
        vals.forEach(val => {
          const mentionedIdx = mentionedItems.findIndex(mO => mO.id === val.id);

          if (mentionedIdx > -1) {
            if (val.agenda_items?.length > 0) {
              mentionedItems[mentionedIdx].agenda_items = _.uniqBy(
                (mentionedItems[mentionedIdx].agenda_items || []).concat(
                  getAgendaItem(val.agenda_items[0])
                ),
                v => [v.agendaItemRank, v.rank].join()
              );
            } else {
              mentionedItems[mentionedIdx].field = _.uniqBy(
                [...mentionedItems[mentionedIdx].field, ...val.field],
                NAME
              );
            }
          } else mentionedItems = mentionedItems.concat(val);
        });
      } else mentionedItems = vals;

      return {
        ...formState,
        [type]: mentionedItems
      };
    });
  };

  const handleEditorChange = (name, value) => {
    setFormState(formState => ({
      ...formState,
      [fsValuesField]: {
        ...formState[fsValuesField],
        metadata: {
          ...formState[fsValuesField].metadata,
          [name]: value
        }
      },
      isReloadOnly: false,
      prevValues:
        !formState.prevValues.metadata ||
        !(name in formState.prevValues.metadata)
          ? {
              ...formState.prevValues,
              metadata: {
                ...formState.prevValues.metadata,
                [name]: value
              }
            }
          : { ...formState.prevValues }
    }));
  };

  const handleCommentChange = (name, value) => {
    name = name.slice(name.indexOf('_') + 1);
    setFormState(formState => {
      const newValue =
        name === NEW_COMMENT
          ? {
              content: value,
              object_id: objId || match?.params?.id,
              user_id: user.info.id
            }
          : value;

      return {
        ...formState,
        [fsValuesField]: {
          ...formState[fsValuesField],
          [name]: newValue
        },
        prevValues:
          (name === NEW_COMMENT &&
            (!formState.prevValues[name] ||
              formState.prevValues[name].user_id !== newValue.user_id)) ||
          (name === NEW_COMMENT_TEXT && !(name in formState.prevValues))
            ? { ...formState.prevValues, [name]: newValue }
            : { ...formState.prevValues }
      };
    });
  };

  const handleBoQReopen = () => {
    handleSubmit(null, {
      ...values,
      status_id: getStatusIdByName(
        objectStatusAll,
        STATUS_FINAL_QUANTITIES_ISSUED
      )
    });
  };

  const setBoQReopen = val => {
    setFormState(s => ({
      ...s,
      showBoQReopen: val
    }));
  };

  const exitPage = (page, data, delAttachments = false) => {
    if (delAttachments) deleteUnsavedAttachments(values.attachments);
    if (page) history.push(page, data);
    else history.goBack();
  };
  const goBack = (page, data) => {
    if (isFormUpdated() && !isCompletedStatus(status, object_type)) {
      setConfirm({
        onConfirm: () => exitPage(page, data, true)
      });
    } else {
      exitPage(page, data);
    }
  };

  const getAgendaItem = item => {
    const agenda = values.agenda.find(agendum => {
      return agendum.rank === item.agendaItemRank;
    });
    item.title = agenda?.title || item.title;
    return item;
  };

  const getLinkedItems = (linkedItems = [], mentionedItems = [], type) => {
    const settlementObjs =
      type === OBJECT && refName === OBJECT_EO
        ? linkedItems.filter(li => li.is_settlement)
        : [];

    /*
      When the object is an EO, filter for settlement objects first.
      Lodash uniqBy results are determined by the order they occur in the array
      so if there are multiple instances of a settlement object,
      the one where is_settlement is true should be saved.
    */
    return _.uniqBy([...settlementObjs, ...mentionedItems], ID);
  };

  const handleSubmit = (event, paramValues) => {
    if (event) event.preventDefault();
    clearTimeout(formState.timer);

    let params = isObjectEmpty(paramValues)
      ? { ...values }
      : { ...paramValues };

    const isDocOrBoQ = isDoc || isBoQ;

    // TODO: use number format on days
    const days = params.metadata.total_schedule_impact_days;
    if (days) params.metadata.total_schedule_impact_days = parseFloat(days);

    // delete marked attachments
    formState.deletedAttachments.forEach(attachmentId => {
      dispatch(
        deleteWithRoute(ATTACHMENTS, attachmentId, `?object_id=${values.id}`)
      );
    });
    let revisionValues = {};
    if (isRevision) {
      if (isObjectEmpty(paramValues)) {
        // skipping this since already done by paramValues from Status
        params = { ...formState.revisionValues };
        if (isDoc) {
          if (params.discipline_type_id) params.discipline_type_id = null;
          if (params.organization) params.organization = null;
        }
      }
      params.isRevision = true;
      revisionValues = {
        attachments: params.attachments,
        linked_objects: params.linked_objects,
        linked_users: params.linked_users
      };
    } else if (!params.metadata.revision) params.metadata.revision = 0;

    const revision = metadata?.revision;
    if (isDraft && !revision) {
      if (isDoc)
        params.name = OBJECT_DOC + `${HYPHEN}${myOrg.org_meta.company_code}`;
      else if (isBoQ)
        params.name =
          `${OBJECT_BOQ}${HYPHEN}` +
          getOrganizationCode(
            projectOrganizations,
            params.metadata.created_by_org_id
          ) +
          HYPHEN +
          getOrganizationCode(projectOrganizations, params.discipline_type_id) +
          `${HYPHEN}${getDisplayPostnr(params.metadata)}`;
      else
        params.name = getObjectName(
          object,
          projectOrganizations,
          refName,
          params.discipline_type_id
        );
    }

    if (refName === OBJECT_ICO) {
      params = evaluateStatus(
        objectStatus,
        params,
        objectDisciplineTypes,
        objectStatusAll
      );
      if (!params.metadata.disciplines) params.metadata.disciplines = {};
    } else if (valueInArray([OBJECT_TA, OBJECT_BHM], refName)) {
      params.organization_id = params.discipline_type_id;
      params.discipline_type_id = params.disc_type_id;
      delete params.disc_type_id;
    } else {
      params.organization_id = params.discipline_type_id;
      delete params.discipline_type_id;
    }

    params.linked_objects = getLinkedItems(
      params.linked_objects,
      formState.mentionedObjs,
      OBJECT
    );
    params.linked_users = getLinkedItems(
      params.linked_users,
      formState.mentionedUsers,
      USER
    );

    if (!params.status_id) params.status_id = params.status.id;

    if (!isObjectEmpty(params.metadata)) {
      const { hasCostEffects, impact } = params.metadata;
      if (
        (refName === OBJECT_ICO &&
          (!hasCostEffects || !Object.values(hasCostEffects).some(d => d))) ||
        ([OBJECT_EO, OBJECT_KOE].includes(refName) &&
          (!impact || !impact.includes(IMPACT_PRICE.toLowerCase())))
      ) {
        delete params.metadata.impact_price_description;
        delete params.metadata.total_cost_nok;
      }
      if (!impact) {
        delete params.metadata.impact_description;
        delete params.metadata.impact_total_schedule_days;
      }
    }

    if (isDocOrBoQ && !params.new_comment_text) delete params.new_comment;

    // if client initiated the BoQ, save client org id in metadata
    if (isBoQ && !objId && params.metadata.created_by_org_id !== orgId)
      params.metadata.requested_by_org_id = orgId;

    // add creator's organization id
    if (!params.metadata.created_by_org_id)
      params.metadata.created_by_org_id = orgId;

    if (isMeeting) {
      params = setRefUsers(params);
      // update status object - needed for email notification PDF (meeting only)
      // cannot change in Status because a lot of existing checking will be affected
      params.status = getStatusById(objectStatus, params.status_id);
    }

    if (params.object_cache)
      params.lock_version = params.object_cache.lock_version;

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        ...revisionValues
      },
      isSaving: true,
      isForward: false,
      mentionedUsers: isDocOrBoQ ? [] : formState.mentionedUsers,
      showBoQReopen: false
    }));

    dispatch(objectCreateOrUpdate(params));
  };

  const handleReload = (merge, isClosedObj = false, isBoQ) => {
    if (typeof merge !== DATA_TYPE_BOOLEAN) {
      setFormState(formState => ({
        ...formState,
        isReloadOnly: true
      }));
      const fetchId = isDoc ? updatedObjFromConflict.id : values.id;
      dispatch(singleObjectFetch(fetchId, false));
    } else {
      if (isBoQ && !isClosedObj) {
        if (
          status?.name === STATUS_DRAFT &&
          (values.id ||
            valueInArray(
              [
                STATUS_FINAL_QUANTITIES_ISSUED,
                STATUS_UPDATED_QUANTITIES_ISSUED,
                STATUS_NOT_USED
              ],
              getStatusById(objectStatus, values.status_id)?.name
            )) &&
          values.id !== updatedObjFromConflict.id
        ) {
          if (values.id) {
            const params = {
              id: values.id,
              status_id: values.status_id,
              updated_object_id: updatedObjFromConflict.id,
              metadata: values.metadata,
              new_comment: values.new_comment_text
                ? {
                    ...values.new_comment,
                    object_id: updatedObjFromConflict.id
                  }
                : null
            };
            setFormState(formState => ({
              ...formState,
              isSaving: true
            }));
            dispatch(objectCreateOrUpdate(params));
          } else {
            const params = {
              ...values,
              id: updatedObjFromConflict.id,
              metadata: {
                ...updatedObjFromConflict.metadata,
                // copy the following values from contractor update
                delmalebrev: values.metadata.delmalebrev,
                contractPercentage: values.metadata.contractPercentage,
                measuredQuantity: values.metadata.measuredQuantity,
                measuredSum: values.metadata.measuredSum
              },
              new_comment: values.new_comment_text
                ? {
                    ...values.new_comment,
                    object_id: updatedObjFromConflict.id
                  }
                : null,
              object_type: updatedObjFromConflict.object_type
            };
            handleSubmit(null, params);
          }
        } else {
          dispatch(singleObjectFetch(updatedObjFromConflict.id, false));
          setFormState(formState => ({ ...formState, reloadComment: true }));
        }
      } else if (isClosedObj) {
        if (isRevision || values.id === updatedObjFromConflict.id) {
          if (isRevision) setRevise();
          dispatch(singleObjectFetch(updatedObjFromConflict.id, false));
        } else dispatch(deleteWithRoute(OBJECTS, values.id));
      } else {
        const dupRevision = [...objectRevisions].reduceRight(
          (_acc, curr, _i, remaining) => {
            if (isCurrentOrg(curr.metadata.revised_by_org_id))
              remaining.splice(0);
            return curr;
          },
          {}
        );

        const previousSentStatus =
          refName === OBJECT_KOE
            ? getPreviousKOESentStatus(dupRevision.history) ??
              getPreviousKOESentStatus(formState.values.history)
            : null;

        const params = getReloadParams(
          dupRevision.id,
          updatedObjFromConflict,
          isRevision ? revisionValues : values,
          refName,
          isCreatorOrg,
          objectStatusAll,
          merge,
          previousSentStatus
        );

        if (merge)
          setFormState(formState => ({
            ...formState,
            selectedRevision: params.metadata.revision
          }));
        dispatch(objectCreateOrUpdate(params));
      }
    }
  };

  const filterTemplateLinkedObj = (agenda, items) => {
    return items.reduce((acc, item) => {
      const include = agenda.some(agenda => {
        if (agenda.rank === item.agendaItemRank) {
          const [cRank, sRank] = item.rank.split('-');
          return agenda.comment.some(comment => {
            if (comment.rank === cRank) {
              if (item.isSubItem)
                return comment.sub_item.some(si => si.rank === sRank);
              return true;
            }
            return false;
          });
        }
        return false;
      });
      return include ? acc.concat(item) : acc;
    }, []);
  };

  const handleTemplate = () => {
    let params = _.cloneDeep(values);
    if (params.metadata.template_id) {
      params.id = params.metadata.template_id;
      delete params.metadata.template_id; // delete own id from metadata
    } else {
      delete params.id; // delete original object id
      params.metadata.created_by_id = user.info.id; // update created_by_id to current user
      params.metadata.created_by_org_id = orgId; // update creator's organization id
    }

    // set name
    params.name = getObjectName(
      object,
      projectOrganizations,
      refName,
      params.discipline_type_id
    );
    // set object type to Template
    const tmpl = getObjectTypeByRefName(objectTypes, OBJECT_TMPL);
    params.object_type = tmpl;
    params.object_type_id = tmpl ? tmpl.id : '';
    // Template is always Draft
    params.status_id = getStatusIdByName(objectStatusAll, STATUS_DRAFT);
    delete params.status;
    // Set organization id from discipline id and delete discipline type id
    params.organization_id = params.discipline_type_id;
    delete params.discipline_type_id;
    delete params.metadata.meeting_date; // Remove meeting date if set
    params = setRefUsers(params); // Set user params

    // https://trello.com/c/VDtB3pTP/508-system-ux-iteration#comment-6180e7bd8e58be7869bfcef8
    params.attachment = [];

    // filter open agenda items
    // caveat: because we have to get the objects linked to the open agenda,
    //         we have to keep the ranking for now.
    params.agenda = params.agenda.reduce((openAgenda, agenda) => {
      const newComment = agenda.comment.reduce((openComments, comment) => {
        if (comment.status === STATUS_OPEN) {
          comment.sub_item = comment.sub_item.reduce((openItems, subItem) => {
            if (subItem.status === STATUS_OPEN) {
              return openItems.concat(subItem);
            }
            return openItems;
          }, []);
          return openComments.concat(comment);
        }
        return openComments;
      }, []);
      agenda.comment = newComment;
      return openAgenda.concat(agenda);
    }, []);

    // get linked objects only for open items
    params.linked_objects =
      params.agenda.length > 0
        ? params.linked_objects.reduce((acc, lO) => {
            if (lO.agenda_items) {
              lO.agenda_items = filterTemplateLinkedObj(
                params.agenda,
                lO.agenda_items
              );
              return lO.agenda_items.length > 0 ? acc.concat(lO) : acc;
            }
            return acc;
          }, [])
        : [];

    // add mentioned objects
    params.linked_objects = getLinkedItems(
      params.linked_objects,
      formState.mentionedObjs,
      OBJECT
    );
    params.linked_users = getLinkedItems(
      params.linked_users,
      formState.mentionedUsers,
      USER
    );

    setFormState(formState => ({
      ...formState,
      isSavingTemplate: true
    }));
    dispatch(objectCreateOrUpdate(params, TEMPLATE));
  };

  const getPreviousKOESentStatus = history =>
    history?.find(h =>
      valueInArray([STATUS_CLAIM_SENT, STATUS_NOTIFIED], h.status?.name)
    )?.status?.name || STATUS_CLAIM_SENT;

  const setRevise = () => {
    const revise = !isRevision;
    if (revise)
      setFormState(formState => {
        const metadata = {
          ...formState.values.metadata,
          revision: getNextObjectRevision(formState.values.metadata.revision),
          prev_version_object_id: formState.values.id
        };
        const previousSentStatus =
          refName === OBJECT_KOE
            ? getPreviousKOESentStatus(formState.values.history)
            : null;
        const revStatus = isDoc
          ? getStatusById(objectStatus, formState.values.status_id)
          : isBoQ
          ? getStatusByName(objectStatusAll, STATUS_DRAFT)
          : getDefaultRevisionStatus(
              isCreatorOrg,
              objectStatusAll,
              previousSentStatus
            );
        let addToMetadata = isDoc ? {} : { revised_by_org_id: orgId };
        addToMetadata = isBoQ
          ? {
              ...addToMetadata,
              delmalebrev: metadata.delmalebrev.map(dm => {
                if (dm.status === STATUS_APPROVED) dm.locked = true;
                return dm;
              })
            }
          : addToMetadata;
        return {
          ...formState,
          isRevision: revise,
          isValid: true,
          revisionValues: {
            ...formState.values,
            status_id: revStatus.id,
            status: revStatus,
            id: null,
            attachments: _.cloneDeep(formState.values.attachments),
            // only specific version should be linked to an object
            // clear linked object list for new revision
            linked_objects: isDoc ? [] : formState.values.linked_objects,
            linked_users: isDoc ? [] : formState.values.linked_users,
            metadata: { ...metadata, ...addToMetadata }
          },
          selectedRevision: metadata.revision,
          mentionedObjs: [],
          mentionedUsers: [],
          deletedAttachments: []
        };
      });
    else {
      clearTimeout(formState.timer);
      // TODO: delete attachments if any
      setFormState(formState => ({
        ...formState,
        isRevision: revise,
        selectedRevision: getPreviousObjectRevision(formState.selectedRevision),
        revisionValues: {}
      }));
    }
  };

  const downloadPdf = () => {
    const disciplines = objectDisciplineTypes[recipientName] || [];
    const disciplineName = objectSelected?.discipline_type?.id
      ? disciplines.find(disc => disc.id === objectSelected.discipline_type.id)
          ?.name || ''
      : '';

    const {
      newHistories,
      sortedDisciplines,
      org_users
    } = processPdfDownloadParams(
      formState.values?.metadata?.revision,
      objectRevisions,
      formState.values,
      projectOrganizations,
      projectSelected
    );

    dispatch(
      generate({
        ...formState.values,
        metadata: {
          ...formState.values.metadata,
          disciplineName: disciplineName,
          disciplines: sortedDisciplines
        },
        bhm_ta_reference_field: !!projectSelected?.settings
          ?.bhm_ta_reference_field,
        created_by: created_by,
        curr_org_name: localStorage.getItem(LS_ORGANIZATION_NAME),
        history: newHistories,
        is_sender_org: isFromSenderOrg(user, created_by_org_id),
        is_string: true,
        org_users: org_users,
        project_name: projectSelected.name,
        sender_info: senderOrgInfo,
        requester: user?.info?.email
      })
    );
  };

  const hasError = field => (formState.errors[field] ? true : false);

  const resetCategoryManagement = () => {
    setCategoryManagement(initCategoryState);
    setShowCategoryInfo(false);
  };

  const manageCategories = (event, category, action) => {
    if (event && action === DELETE) event.stopPropagation();

    switch (action) {
      case ADD: {
        const revision_type = revisionTypes.find(
          type => type.action_name === DEFAULT_REVISION_TYPE
        );
        category = {
          ...category,
          name: '',
          id: null,
          ancestry: category,
          ancestry_id: category.id,
          revision_type,
          revision_type_id: revision_type?.id || null
        };
        break;
      }
      case DELETE:
        deleteCategory(category.id);
        break;
      default:
        break;
    }

    setCategoryManagement({
      action,
      category,
      errors: action === ADD ? validateDocumentCategory(category) : {},
      original_category: action === EDIT ? category : null
    });
  };

  const handleCMChange = (event, value, field) => {
    if (event) event.persist();

    let newCategoryParams = { [field]: value };
    switch (field) {
      case ANCESTRY:
        newCategoryParams = {
          ancestry: value,
          ancestry_id: value?.id || null
        };
        break;
      case REVISION: {
        newCategoryParams = {
          revision_type: value,
          revision_type_id: value?.id || null
        };
        break;
      }
      default:
        break;
    }

    setCategoryManagement(cm => {
      const updatedCategory = {
        ...cm.category,
        ...newCategoryParams
      };
      let excludeList = getExistingCategoryNames(
        updatedCategory?.ancestry,
        documentCategories
      );
      if (
        cm.original_category &&
        cm.original_category.ancestry?.id === updatedCategory?.ancestry?.id
      )
        excludeList = excludeList.filter(e => e !== cm.original_category.name);

      return {
        ...cm,
        category: updatedCategory,
        errors: validateDocumentCategory(updatedCategory, excludeList)
      };
    });
  };

  const handleCMSave = () => {
    const selected_category_path = getCategoryInfo(
      categories,
      categoryManagement.category
    );

    dispatch(
      createOrUpdateDocumentCategory(CATEGORIES, CATEGORY, {
        ...categoryManagement.category,
        selected_category_path,
        project_id: projectId
      })
    );
  };

  const deleteCategory = category_id => {
    const del = window.confirm(
      intl.formatMessage(
        { id: 'object.DELETE_ITEM_CONFIRMATION' },
        { name: intl.formatMessage({ id: 'common.CATEGORY' }) }
      ) +
        '\n' +
        intl.formatMessage({ id: 'common.ACTION_CANNOT_UNDONE' })
    );
    if (del) dispatch(deleteDocumentCategory(CATEGORIES, category_id));
  };

  const updateFromRevisedObj = () => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        metadata: { ...formState.values.metadata, updated_from_revised: false }
      }
    }));
    setFromRevisedObj(false);
  };

  //////////////////////////////////////////////////////////////////////////////
  //                             END OF FUNCTIONS                             //
  //                            START OF VARIABLES                            //
  //////////////////////////////////////////////////////////////////////////////

  const {
    access_policy_change_status: statusEdit,
    access_policy_comments,
    access_policy_delete,
    access_policy_distribution,
    access_policy_revise,
    access_policy_unshared,
    access_policy_write,
    attachments,
    discipline_type_id,
    id: objId,
    linked_objects = [],
    metadata,
    name,
    object_type,
    object_type_id,
    organization,
    status,
    title
  } = isRevision ? revisionValues : values;
  const statusName = status?.name;
  const isDraft = statusName === STATUS_DRAFT;
  const selectedRevNo =
    selectedRevision && isNaN(selectedRevision)
      ? convertLettersToNum(selectedRevision) - 1
      : selectedRevision;
  const disableEdit =
    objId && !isForward && !isRevision ? !access_policy_write : false;
  const isLatestRevision =
    selectedRevNo === objectRevisions.at(-1)?.metadata?.revision;
  const showRevise =
    !isForward && ((access_policy_revise && isLatestRevision) || isRevision);
  const formSent = statusName ? isSentStatus(statusName) : false;
  const projectOrganizations = projectSelected?.organizations || [];
  const projectOrganizationHierarchy =
    projectSelected?.organization_hierarchy || [];
  const myOrg = projectOrganizations.find(po => isCurrentOrg(po.id));
  const currentOrgType = getCurrentOrgType(projectOrganizations);
  // discipline type id may not be set (ie when changing from new object page)
  const refNameDiscLabel = selectedObjType?.ref_name || '';
  const disciplineLabel =
    refNameDiscLabel === OBJECT_ICO
      ? LBL_SELECT_DISCIPLINE_TYPE
      : LBL_SELECT_RECIPIENT;

  const objTypes = filterObjTypesList(
    objectTypes,
    topAccessLevel,
    currentOrgType
  );

  const userSuggestionOrgs = isBoQ
    ? projectOrganizations.filter(po =>
        valueInArray(
          [discipline_type_id, values?.metadata?.created_by_org_id],
          po.id
        )
      )
    : projectOrganizations;
  const honorableUsers = userSuggestionOrgs.reduce(
    (acc, curr) => [
      ...acc,
      ...curr.users.map(user => ({
        id: user.id,
        name: user.name || user.email,
        type: USER
      }))
    ],
    []
  );
  const honorableObjects = objectsAll.reduce((acc, curr) => {
    if (curr.id === objId) return acc;

    return [
      ...acc,
      {
        id: curr.id,
        name: `${getDisplayName(curr.name)} ${curr.title}`,
        link: `/object/${curr.id}`,
        object_type: curr.object_type.ref_name,
        type: OBJECT
      }
    ];
  }, []);
  const honorableObjTypes = objTypes.reduce((acc, curr) => {
    return [
      ...acc,
      {
        name: `${NEW} ${curr.name}`,
        link: `${process.env.REACT_APP_DORADO_URL}/object/new?objectType=${curr.id}`,
        objectType: curr,
        type: OBJECT
      }
    ];
  }, []);

  const suggestions = [
    ...honorableUsers,
    ...(honorableUsers.length > 0 ? [{ type: DIVIDER }] : []),
    ...honorableObjects,
    ...honorableObjTypes
  ];

  let flowObjects = linked_objects ? [...linked_objects] : [];
  formState.mentionedObjs.forEach(mo => {
    if (
      mo.name &&
      !flowObjects.find(
        fo => fo.id === mo.id || mo.name === `${fo.name} ${fo.title}`
      )
    ) {
      const [name, ...title] = mo.name.split(' ');
      flowObjects.push({ ...mo, name, title: mo.title || title.join(' ') });
    }
  });

  if (objId && !flowObjects.find(obj => obj.id === objId))
    flowObjects.unshift({
      id: objId,
      created: values?.created,
      name: name,
      title: title,
      object_type: object_type.ref_name
    });

  const disableComment = !(isBoQ && access_policy_comments && isLatestRevision);
  const hideSaveButton = isDoc
    ? disableEdit && !statusEdit && !access_policy_distribution
    : !access_policy_revise &&
      !access_policy_distribution &&
      disableComment &&
      (refName !== OBJECT_ICO ? disableEdit : disableEdit && !hasDiscAccess);
  const objectRefName = isNew
    ? isForward
      ? discipline_type_id
        ? getObjectName(
            object,
            projectOrganizations,
            refName,
            discipline_type_id
          )
        : ''
      : isDoc
      ? LBL_CREATE_NEW_DOC_OBJECT
      : LBL_CREATE_NEW_EXEC_OBJECT
    : getDisplayName(name);

  const {
    created_by_id,
    created_by_org_id,
    meeting_no: meetingNo,
    selected_category,
    template_id: templateId
  } = metadata;
  const { user: created_by, org: senderOrgInfo, org_name } = getObjectCreator(
    projectOrganizations,
    created_by_org_id || orgId,
    created_by_id,
    user
  );
  const isCreatorOrg = created_by_org_id
    ? isFromSenderOrg(user, created_by_org_id)
    : true;
  const recipientName = organization
    ? getOrganizationName(projectOrganizations, organization.id)
    : refName !== OBJECT_ICO
    ? getOrganizationName(projectOrganizations, discipline_type_id)
    : '';
  const creatorOrgName = org_name || localStorage.getItem(LS_ORGANIZATION_NAME);
  let disciplineTypes;
  switch (refName) {
    case OBJECT_ICO:
      disciplineTypes = getUserDisciplines(
        myOrg,
        user,
        objectDisciplineTypes[creatorOrgName],
        statusName
      );
      break;
    case OBJECT_BOQ:
      disciplineTypes = projectOrganizations.filter(
        po => po.id === discipline_type_id
      );
      break;
    case OBJECT_BHM:
    case OBJECT_EO:
    case OBJECT_KOE:
    case OBJECT_TA:
      disciplineTypes = getRecipientList(
        refName,
        projectOrganizations,
        currentOrgType,
        formSent ? discipline_type_id : ''
      );
      break;
    default:
      disciplineTypes = projectOrganizations;
  }

  const isInMeetingSeries = !!(templateId && meetingNo);
  const titleGridSize = isMeeting ? (isInMeetingSeries ? 9 : 12) : 6;

  return (
    <div className={classes.objectRoot}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        key={'autosaveProgress'}
        open={isSaving || isSavingTemplate}
        TransitionComponent={Slide}>
        <Alert
          icon={<CircularProgress disableShrink size={16} />}
          severity="info">
          {isSavingTemplate ? (
            <FormattedMessage
              defaultMessage="Saving to Template"
              id="object.SAVING_CHANGES_TEMPLATE"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Saving changes"
              id="common.SAVING_CHANGES"
            />
          )}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        data-testid="saved-snackbar"
        key={'autosaveSuccess'}
        open={
          !isNew &&
          !isForward &&
          !isFormUpdated() &&
          !objectLoading &&
          (objectUpdated ||
            (history.action === HISTORY_REPLACE && !revisionChanged)) &&
          !valueInArray([STATUS_READ, STATUS_REVISED], statusName)
        }
        TransitionComponent={Slide}>
        <Alert severity="success">
          {templateUpdated ? (
            <FormattedMessage
              defaultMessage="The template has been saved!"
              id="object.SAVED_CHANGES_TEMPLATE"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Your changes have been saved!"
              id="common.SAVED_CHANGES"
            />
          )}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        key={'autosaveSuccess1'}
        open={templateUpdated}
        TransitionComponent={Slide}>
        <Alert severity="success">
          <FormattedMessage
            defaultMessage="The template has been saved!"
            id="object.SAVED_CHANGES_TEMPLATE"
          />
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        key={'autosaveError'}
        open={!_.isEmpty(objectError)}
        TransitionComponent={Slide}>
        <Alert severity="error">{objectError}</Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        className={classes.tmplSnackbar}
        key={'tmplError'}
        open={tmplError}
        TransitionComponent={Slide}>
        <Alert severity="error">
          <FormattedMessage
            defaultMessage="Error updating template!"
            id="object.UPDATE_TEMPLATE_ERROR"
          />
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={4000}
        key={'categoryError'}
        onClose={() => dispatch(clearDocumentMessages())}
        open={!!categoryError}
        TransitionComponent={Slide}>
        <Alert severity="error">{categoryError}</Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        key={'revisedDraftInfo'}
        onClose={updateFromRevisedObj}
        open={fromRevisedObj}
        TransitionComponent={Slide}>
        <Alert onClose={updateFromRevisedObj} severity="info">
          <FormattedMessage
            defaultMessage="The object has been updated and your draft has been copied to the latest revision."
            id="info.DRAFT_FROM_REVISED_OBJECT"
          />
        </Alert>
      </Snackbar>
      {updatedObjFromConflict ? (
        <OverrideAlert
          conflictObject={updatedObjFromConflict}
          currObjectStatus={getStatusById(objectStatus, values.status_id)?.name}
          handleReload={handleReload}
          setHasPopup={setHasPopup}
        />
      ) : null}
      {confirm ? (
        <Confirm
          {...confirm}
          abortLabel={
            <FormattedMessage
              default="Stay on this page"
              id="object.STAY_ON_THIS_PAGE"
            />
          }
          confirmLabel={
            <FormattedMessage
              default="Leave this page"
              id="object.LEAVE_THIS_PAGE"
            />
          }
          message={
            <Typography>
              <FormattedMessage
                default="You will lose any unsaved changes if you navigate away from the page."
                id="object.NAVIGATE_UNSAVED_CHANGES"
              />
            </Typography>
          }
          onClose={() => setConfirm(null)}
          setHasPopup={setHasPopup}
        />
      ) : null}
      {showBoQReopen ? (
        <Confirm
          abortLabel={<FormattedMessage default="No" id="common.NO" />}
          confirmLabel={<FormattedMessage default="Yes" id="common.YES" />}
          message={
            <Typography>
              <FormattedMessage
                default='Are you sure you want to reopen the BoQ? It will revert to status "Final quantities issued" and sender will be able to revise.'
                id="object.BOQ_CONFIRM_REOPEN"
              />
            </Typography>
          }
          onClose={() => setBoQReopen(false)}
          onConfirm={handleBoQReopen}
        />
      ) : null}
      <NoButtonPopup
        handleParentClose={resetCategoryManagement}
        setHasPopup={setHasPopup}
        show={showCategoryInfo}>
        <div className={classes.componentPopupRoot}>
          <Grid alignItems="center" container spacing={2}>
            <CategoryInfo
              categories={documentCategories}
              errors={categoryManagement.errors}
              fnCancel={resetCategoryManagement}
              fnChange={handleCMChange}
              fnSave={handleCMSave}
              isOpen={showCategoryInfo}
              selected_category={categoryManagement.category}
            />
          </Grid>
        </div>
      </NoButtonPopup>
      <Grid container spacing={2}>
        <Grid className={classes.breadCrumbs} item md={12} xs={12}>
          <IconButton
            className={classes.disableHoverEffect}
            edge="start"
            onClick={() => history.goBack()}>
            <ArrowBackIcon />
          </IconButton>
          <BreadCrumbs
            goBack={goBack}
            objectName={
              !isForward ? (
                objectRefName
              ) : (
                <FormattedMessage
                  defaultMessage="Forward {name}"
                  id="common.FORWARD_NAME"
                  values={{ name: name }}
                />
              )
            }
            orgId={orgId}
            orgName={localStorage.getItem(LS_ORGANIZATION_NAME)}
            projectId={projectSelected.id}
            projectName={projectSelected.name}
          />
        </Grid>
        <Grid className={classes.cardContainer} container>
          <Grid className={classes.cardContentLeft} item md={7} xs={12}>
            <Card>
              <form>
                <NoBgCardAction
                  action={
                    <div className={classes.displayFlex}>
                      <Status
                        disabled={objId ? !statusEdit && !isRevision : false}
                        formState={formState}
                        handleSubmit={handleSubmit}
                        isBoQ={isBoQ}
                        isCreatorOrg={isCreatorOrg}
                        isDoc={isDoc}
                        name={refName}
                        recipientId={discipline_type_id}
                        recipientName={recipientName}
                        setFormState={setFormState}
                        setHasPopup={setHasPopup}
                        showVoid={!!objId}
                        status={statusName}
                        topAccessLevel={topAccessLevel}
                      />
                      {objId || isRevision ? (
                        <History
                          component="span"
                          currRevision={metadata.revision}
                          histories={values.history}
                          recipientName={recipientName}
                          refName={refName}
                          revisionDisplay={
                            metadata.revision_sequence_display || ''
                          }
                          revisions={objectRevisions}
                        />
                      ) : null}
                      {!isForward &&
                      !isNew &&
                      isSentStatus(statusName) &&
                      valueInArray([OBJECT_BHM, OBJECT_TA], refName) ? (
                        <MorePopover
                          formState={formState}
                          history={history}
                          object={object}
                          setFormState={setFormState}
                          status={objectStatusAll}
                        />
                      ) : null}
                    </div>
                  }
                  className={classes.formHeader}
                  title={
                    <div className={classes.displayFlex}>
                      {objectRefName}
                      <RevisionPopover
                        disableEdit={
                          !(isDoc && isLatestRevision) || access_policy_unshared
                        }
                        displayObjectView={displayObjectView}
                        isForward={isForward}
                        isRevision={isRevision}
                        isViewOpen={!!viewState.objectId}
                        revisions={objectRevisions}
                        selectedRevision={selectedRevision}
                        setFormState={setFormState}
                      />
                    </div>
                  }
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item md={titleGridSize} xs={12}>
                      <TextField
                        disabled={
                          isBoQ ||
                          (!(isDoc && isCreatorOrg && isLatestRevision) &&
                            (formSent || disableEdit || selectedRevNo > 0))
                        }
                        error={hasError(TITLE)}
                        fullWidth
                        helperText={hasError(TITLE) ? fsErrors.title[0] : null}
                        id={TITLE}
                        label={
                          <FormattedMessage
                            defaultMessage="Title"
                            id="common.TITLE"
                          />
                        }
                        name={TITLE}
                        onChange={handleChange}
                        required
                        size="small"
                        type="text"
                        value={title || ''}
                        variant="outlined"
                      />
                    </Grid>
                    {isInMeetingSeries ? (
                      <Grid item md={3} xs={12}>
                        <TextField
                          disabled
                          fullWidth
                          inputProps={{ style: { textAlign: 'right' } }}
                          label={
                            <FormattedMessage
                              defaultMessage="Meeting series #"
                              id="object.MEETING_SERIES_NO"
                            />
                          }
                          size="small"
                          type="text"
                          value={formatNumberDisplay(meetingNo)}
                          variant="outlined"
                        />
                      </Grid>
                    ) : null}
                    {isDoc ? (
                      <Grid item md={6} xs={12}>
                        <Autocomplete
                          classes={{
                            option: classes.autocompleteOption
                          }}
                          disabled={
                            disableEdit ||
                            (!isCreatorOrg && topAccessLevel !== ROLE_PL) ||
                            (!objId && isRevision)
                          }
                          getOptionLabel={option => {
                            return option.name
                              ? intl.formatMessage({
                                  defaultMessage: option.name,
                                  id: 'object.' + option.name.toUpperCase()
                                })
                              : '';
                          }}
                          id={SELECTED_CATEGORY}
                          name={SELECTED_CATEGORY}
                          onChange={(_e, value) => handleCategoryChange(value)}
                          options={categories}
                          renderInput={params => (
                            <TextField
                              {...params}
                              error={hasError(SELECTED_CATEGORY)}
                              fullWidth
                              helperText={
                                hasError(SELECTED_CATEGORY)
                                  ? fsErrors.selected_category[0]
                                  : null
                              }
                              label={
                                <FormattedMessage
                                  defaultMessage="Select category"
                                  id="object.SELECT_CATEGORY"
                                />
                              }
                              required
                              size="small"
                              variant="outlined"
                            />
                          )}
                          renderOption={option => (
                            <>
                              <Typography
                                style={{
                                  marginLeft: option.indent_count
                                }}>
                                {intl.formatMessage({
                                  defaultMessage: option.name,
                                  id: 'object.' + option.name.toUpperCase()
                                })}
                              </Typography>
                              <div>
                                <Button
                                  className={option.id ? classes.hidden : null}
                                  onClick={e =>
                                    manageCategories(e, option, ADD)
                                  }
                                  size="small">
                                  <Typography variant="overline">
                                    <FormattedMessage
                                      defaultMessage="Add category"
                                      id="object.ADD_CATEGORY"
                                    />
                                  </Typography>
                                </Button>
                                <IconButton
                                  aria-label="edit"
                                  className={classes.hidden}
                                  onClick={e =>
                                    manageCategories(e, option, EDIT)
                                  }
                                  size="small">
                                  <EditIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                  aria-label="edit"
                                  className={classNames(
                                    classes.deleteIcon,
                                    classes.hidden
                                  )}
                                  onClick={e =>
                                    manageCategories(e, option, DELETE)
                                  }
                                  size="small">
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </div>
                            </>
                          )}
                          size="small"
                          value={selected_category || null}
                        />
                      </Grid>
                    ) : !isMeeting ? (
                      <Grid item md={6} xs={12}>
                        <TextField
                          disabled={
                            (disableEdit ||
                              !object_type_id ||
                              !isDraft ||
                              isBoQ ||
                              selectedRevNo > 0) &&
                            !isForward
                          }
                          error={hasError(DISCIPLINE_TYPE_ID)}
                          fullWidth
                          helperText={
                            hasError(DISCIPLINE_TYPE_ID)
                              ? fsErrors.discipline_type_id[0]
                              : null
                          }
                          InputLabelProps={{ shrink: true }}
                          label={disciplineLabel}
                          name={DISCIPLINE_TYPE_ID}
                          onChange={handleChange}
                          required
                          select
                          SelectProps={{ native: true }}
                          size="small"
                          value={discipline_type_id || ''}
                          variant="outlined">
                          <option disabled key="defaultValue" value="" />
                          {disciplineTypes.map(option => (
                            <option key={option.name} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                    ) : null}
                  </Grid>
                </CardContent>
                {(discipline_type_id || isDoc || isMeeting || isForward) &&
                TagName ? (
                  <TagName
                    access_policy_distribution={access_policy_distribution}
                    clearTimer={clearTimer}
                    createdBy={created_by}
                    disableEdit={disableEdit}
                    displayObjectView={displayObjectView}
                    formState={formState}
                    handleCb={handleCbChange}
                    handleChange={handleMetadataChange}
                    handleCommentChange={handleCommentChange}
                    handleEditorChange={handleEditorChange}
                    handleMentionItems={handleMentionItems}
                    hasError={hasError}
                    hasNewMomNumbering={hasNewMomNumbering}
                    hasPopup={hasPopup}
                    history={history}
                    isCreatorOrg={isCreatorOrg}
                    isForward={isForward}
                    isLatestRevision={isLatestRevision}
                    isNew={isNew}
                    isReloading={isReloadOnly}
                    isRevision={isRevision}
                    objectStatus={objectStatus}
                    objectTypes={objectTypes}
                    organizationHierarchy={projectOrganizationHierarchy}
                    organizations={projectOrganizations}
                    projectId={projectId}
                    queryParams={location.search}
                    recipientName={recipientName}
                    refName={refName}
                    senderOrgInfo={senderOrgInfo}
                    setDiscAccess={setDiscAccess}
                    setFormState={setFormState}
                    setHasPopup={setHasPopup}
                    suggestions={suggestions}
                    topAccessLevel={topAccessLevel}
                  />
                ) : null}
                {!isDoc ? (
                  <>
                    <Attachment
                      formState={formState}
                      handleAttachmentClick={handleAttachmentClick}
                      hasError={hasError}
                      isEditable={!disableEdit}
                      orgId={orgId}
                      projectId={projectId}
                      setFormState={setFormState}
                      setHasPopup={setHasPopup}
                    />
                    {/*
                      // https://trello.com/c/FVl7hFRH/549-mentioned-objects#comment-61d4023898c91a2d282b41e5
                      <LinkedObject
                        displayObjectView={displayObjectView}
                        formState={formState}
                        isEditable={!disableEdit}
                        objectTypes={objTypes}
                        organizations={projectOrganizations}
                        projectId={projectId}
                        setFormState={setFormState}
                        setHasPopup={setHasPopup}
                      />
                    */}
                  </>
                ) : null}
                <Divider />
                <CardActions>
                  {hideSaveButton ? (
                    isDoc ? null : (
                      <Button
                        className={classes.alignRightButton}
                        color="secondary"
                        onClick={downloadPdf}
                        variant="contained">
                        <FormattedMessage
                          defaultMessage="Download PDF"
                          id="common.DOWNLOAD_PDF"
                        />
                      </Button>
                    )
                  ) : (
                    <>
                      {isDoc ? null : (
                        <Button
                          className={classes.alignRightButton}
                          color="secondary"
                          disabled={
                            !isValid || isSaving || objectLoading || isNew
                          }
                          onClick={downloadPdf}
                          variant="contained">
                          <FormattedMessage
                            defaultMessage="Download PDF"
                            id="common.DOWNLOAD_PDF"
                          />
                        </Button>
                      )}
                      {isMeeting && objId && !templateId ? (
                        <Button
                          className={classes.alignRightButton}
                          color="secondary"
                          disabled={!isValid || !title}
                          onClick={handleTemplate}
                          variant="contained">
                          <FormattedMessage
                            defaultMessage="Save Template"
                            id="object.SAVE_TEMPLATE"
                          />
                        </Button>
                      ) : null}
                      {(objId && statusEdit && isDraft) ||
                      access_policy_delete ? (
                        <Popup
                          button={
                            <Button
                              className={compClasses.deleteButton}
                              data-testid="delete-object-btn"
                              style={{ margin: 0 }}>
                              {isDraft && selectedRevNo > 0 ? (
                                <FormattedMessage
                                  defaultMessage="Delete Draft"
                                  id="object.DELETE_DRAFT"
                                />
                              ) : (
                                <FormattedMessage
                                  defaultMessage="Delete"
                                  id="common.DELETE"
                                />
                              )}
                            </Button>
                          }
                          className={classes.alignRightButton}
                          dataTestId="popup-delete-object">
                          <Delete
                            deleteFn={deleteWithRoute}
                            error={objectError}
                            isDeleted={currObjDeleted}
                            isRevisionDraft={isDraft && selectedRevNo > 0}
                            name={
                              <FormattedMessage
                                defaultMessage="Object"
                                id="common.OBJECT"
                              />
                            }
                            objects={{
                              [objId]: `${objectRefName} ${title}`
                            }}
                            parentId={OBJECTS}
                          />
                        </Popup>
                      ) : null}
                      {showRevise ? (
                        isDoc && attachments.length === 1 ? (
                          <Popup
                            button={
                              <Button color="secondary" variant="contained">
                                <FormattedMessage
                                  defaultMessage="Revise"
                                  id="attachment.REVISE"
                                />
                              </Button>
                            }
                            className={classes.alignRightButton}>
                            <DocUploader
                              dropZoneProps={{
                                multiple: false,
                                maxFiles: 1
                              }}
                              isRevision
                              object={values}
                              projectId={projectId}
                              uploadPath={getUploadPath(projectId)}
                            />
                          </Popup>
                        ) : (
                          <Button
                            className={classes.alignRightButton}
                            color="secondary"
                            id="revise-btn"
                            onClick={setRevise}
                            variant="contained">
                            {isRevision ? (
                              <FormattedMessage
                                defaultMessage="Cancel"
                                id="common.CANCEL"
                              />
                            ) : (
                              <FormattedMessage
                                defaultMessage="Revise"
                                id="attachment.REVISE"
                              />
                            )}
                          </Button>
                        )
                      ) : null}
                      <Button
                        className={classes.alignRightButton}
                        color="secondary"
                        disabled={
                          !isValid ||
                          isSaving ||
                          objectLoading ||
                          (disableEdit &&
                            disableComment &&
                            !access_policy_distribution) ||
                          (isBoQ && !objId ? false : !isFormUpdated())
                        }
                        id="save-btn"
                        onClick={handleSubmit}
                        type="submit"
                        variant="contained">
                        <FormattedMessage
                          defaultMessage="Save changes"
                          id="common.SAVE_CHANGES"
                        />
                      </Button>
                    </>
                  )}

                  {isBoQ &&
                  statusEdit &&
                  isCompletedStatus(statusName, object_type) ? (
                    <Button
                      className={classes.alignRightButton}
                      color="secondary"
                      onClick={() => setBoQReopen(true)}
                      variant="contained">
                      <FormattedMessage
                        defaultMessage="Reopen"
                        id="object.REOPEN"
                      />
                    </Button>
                  ) : null}
                </CardActions>
              </form>
            </Card>
          </Grid>
          <Grid className={classes.cardContentRight} item md={5} xs={12}>
            <DocumentFlow
              displayObjectView={displayObjectView}
              hasAttachment={attachments?.length > 0}
              history={history}
              isBoQ={isBoQ}
              isCompletedStatus={isCompletedStatus(status, object_type)}
              isDoc={isDoc}
              isFormUpdated={isFormUpdated}
              isProjectArchived={projectSelected.archived}
              isUnshared={access_policy_unshared}
              objectId={objId}
              objects={flowObjects}
              organizations={projectOrganizations}
              projectId={projectId}
              setConfirm={setConfirm}
              setFormState={setFormState}
              state={viewState}
              topAccessLevel={topAccessLevel}
              types={objectTypes}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

ObjectInfo.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object
};

export default withRouter(ObjectInfo);
