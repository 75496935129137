import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert } from '@material-ui/lab';
import { Box } from '@material-ui/core';
import { actionOnS3File } from 'common/ursa';
import {
  diffWithCurrDate,
  downloadS3File,
  formatDate,
  isCurrentOrg,
  isObjectEmpty,
  valueInArray
} from 'common/helper';
import { sortDisciplinesByOrganization } from 'common/sorting';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import { createEditorStateWithText } from '@draft-js-plugins/editor';
import {
  ACTION_DELETE,
  DEFAULT_DATE_FORMAT,
  DIR_PROJECTS,
  DIR_TEMP,
  DOT,
  ELLIPSIS,
  ERROR,
  FORWARD_SLASH,
  HYPHEN,
  LS_ORGANIZATION_CODE,
  LS_ORGANIZATION_ID,
  LS_ORGANIZATION_NAME,
  MAIN,
  OBJECT,
  OBJECT_BHM,
  OBJECT_BOQ,
  OBJECT_DISP,
  OBJECT_DOC,
  OBJECT_DOCUMENTATION,
  OBJECT_EO,
  OBJECT_ICO,
  OBJECT_KOE,
  OBJECT_REF,
  OBJECT_TA,
  OBJECT_TMPL,
  ORG_TYPE_CLIENT,
  ORG_TYPE_CONSULTANT,
  ORG_TYPE_CONTRACTOR,
  ORG_TYPE_SUB_CONSULTANT,
  ORG_TYPE_SUB_CONTRACTOR,
  REGEX_LAST_DOT,
  REGEX_ZERO,
  REVISION_LABEL,
  ROLE_OBSERVER,
  ROLE_PARTICIPANT,
  SS_STORED_PROJECT_ID,
  STATUS_APPROVED_AND_CLOSED,
  STATUS_DRAFT,
  STATUS_OPEN,
  STATUS_PRELIM_BOQ_APPROVED,
  STATUS_REJECTED,
  STATUS_REVISED,
  USER,
  VIRUS_SCAN_CLEAN,
  VIRUS_SCAN_INFECTED,
  WARNING
} from 'common/constants';
import { getDefaultRevisionStatus } from './statusProcessor';

export const getDefaultObjectTitle = refName => `NY ${refName}`;

export const getStatusIdByName = (allStatuses, statusName) => {
  const status = allStatuses.find(s => s.name === statusName);
  return status?.id || '';
};

export const getStatusById = (allStatuses, statusId) => {
  return allStatuses.find(s => s.id === statusId);
};

export const getStatusByName = (allStatuses, statusName) => {
  return allStatuses.find(s => s.name === statusName);
};

export const isFromSenderOrg = (user, sender_org_id) => {
  return (
    user?.info?.organizations?.some(o => o.id === sender_org_id) ||
    isCurrentOrg(sender_org_id)
  );
};

export const getObjectCreator = (organizations, org_id, user_id, user) => {
  const org = org_id && organizations?.find(org => org.id === org_id);
  const projUser = user_id && org?.users?.find(u => u.id === user_id);
  let userStr = '';
  if (projUser) {
    userStr = projUser.name
      ? `${projUser.name} (${projUser.email})`
      : projUser.email;
  } else if (user?.info) {
    userStr = user.info.name
      ? `${user.info.name} (${user.info.email})`
      : user.info.email;
  }

  return {
    org_name: org?.name || '',
    org: org
      ? `${org.name}`.concat(
          org.org_meta?.address ? `\n${org.org_meta.address}` : ''
        )
      : '',
    user: userStr
  };
};

export const getOrgInfo = (organizations, orgId) => {
  const org =
    organizations && orgId && organizations.find(org => org.id === orgId);
  let info = '';
  if (org) {
    info = `${org.name}`.concat(
      org.org_meta && org.org_meta.address ? `\n${org.org_meta.address}` : ''
    );
  }
  return info;
};

export const getObjectTypeById = (objectTypes, id) =>
  objectTypes.find(v => v.id === id);

export const getObjectTypeByRefName = (objectTypes, name) =>
  objectTypes.find(v => v.ref_name === name);

// remove BOQ, Discipline, Template,
//        KOE if sender is Client type,
//        KOE and EO if user only has participant role
export const filterObjTypesList = (
  objectTypes,
  topAccessLevel,
  currentOrgType
) => {
  return objectTypes.filter(t => {
    const isNotKOE = OBJECT_KOE !== t.ref_name;
    const filterOutType = valueInArray(
      [OBJECT_BOQ, OBJECT_DISP, OBJECT_DOC, OBJECT_TMPL],
      t.ref_name
    );
    if (ROLE_PARTICIPANT === topAccessLevel)
      return isNotKOE && !filterOutType && OBJECT_EO !== t.ref_name;
    if (ORG_TYPE_CLIENT === currentOrgType) return isNotKOE && !filterOutType;
    return !filterOutType;
  });
};

export const getObjectName = (object, projectOrgs, refName, disc_type_id) => {
  let name = '';
  if (refName === OBJECT_ICO) {
    const currOrgName = localStorage.getItem(LS_ORGANIZATION_NAME);
    const disc =
      object.disciplineTypes[currOrgName] &&
      object.disciplineTypes[currOrgName].length > 0 &&
      object.disciplineTypes[currOrgName].filter(d => d.id === disc_type_id)[0];
    name = disc?.code || '';
  } else if (refName === OBJECT_REF) {
    name = localStorage.getItem(LS_ORGANIZATION_CODE);
  } else {
    const selectedOrg = projectOrgs.filter(org => org.id === disc_type_id)[0];
    name = `${localStorage.getItem(LS_ORGANIZATION_CODE)}-${selectedOrg
      ?.org_meta.company_code || ''}`;
  }

  return `${refName}-${name}`;
};

export const getDisplayName = name => {
  if (name && name.startsWith(OBJECT_DOC)) {
    const tokens = name
      .split('-')
      .slice(1)
      .join('-');
    name = tokens || name;
  }
  return name;
};

export const getCurrentOrgType = projectOrganizations => {
  const org = projectOrganizations.find(org => isCurrentOrg(org.id));
  return org?.organization_type.name || '';
};

export const getOrganizationName = (organizations, id) => {
  const org =
    organizations && id ? organizations.find(org => org.id === id) : null;
  return org?.name || '';
};

export const getOrganizationCode = (organizations, id) => {
  const org =
    organizations && id ? organizations.find(org => org.id === id) : null;
  return org?.org_meta.company_code || '';
};

const koeRecipientsBySender = {
  [ORG_TYPE_CONTRACTOR]: [ORG_TYPE_CLIENT],
  [ORG_TYPE_CONSULTANT]: [ORG_TYPE_CLIENT, ORG_TYPE_CONTRACTOR],
  [ORG_TYPE_SUB_CONTRACTOR]: [ORG_TYPE_CONTRACTOR],
  [ORG_TYPE_SUB_CONSULTANT]: [ORG_TYPE_CONSULTANT]
};

export const getRecipientList = (
  refName,
  organizations,
  currentOrgType,
  disciplineTypeId
) => {
  if (disciplineTypeId) {
    const index = organizations.findIndex(org => org.id === disciplineTypeId);
    return index > -1 ? [organizations[index]] : [];
  } else {
    const arr =
      refName === OBJECT_KOE ? koeRecipientsBySender[currentOrgType] : [];
    return organizations.reduce((orgs, org) => {
      let addOrg;
      if (!isCurrentOrg(org.id)) {
        switch (refName) {
          case OBJECT_KOE:
            addOrg = arr.some(ot => ot === org.organization_type.name)
              ? org
              : addOrg;
            break;
          case OBJECT_BHM:
          case OBJECT_EO:
            if (currentOrgType === ORG_TYPE_CLIENT)
              addOrg = [ORG_TYPE_CONTRACTOR, ORG_TYPE_CONSULTANT].some(
                ot => ot === org.organization_type.name
              )
                ? org
                : addOrg;
            else
              addOrg =
                org.organization_type.name !== ORG_TYPE_CLIENT ? org : addOrg;
            break;
          default:
            addOrg = org;
            break;
        }
      }
      return addOrg ? [...orgs, addOrg] : orgs;
    }, []);
  }
};

export const getUserDisciplines = (
  organization,
  user,
  allDisciplines = [],
  status
) => {
  if (status && status !== STATUS_DRAFT) return allDisciplines;

  let userDisc = [];
  const org =
    organization?.length > 0 // organization may be an array or an object
      ? organization.find(o => isCurrentOrg(o.id))
      : organization;
  if (org?.users && user?.info?.id) {
    const org_user = org.users.find(ou => ou.id === user.info.id);
    if (org_user) {
      userDisc = org_user.project_settings
        .slice(0)
        .reduce((acc, ps, _i, rest) => {
          if (ps.discipline_type) return acc.concat(ps.discipline_type);
          else {
            rest.splice(1); // eject early by mutating iterated copy
            return allDisciplines;
          }
        }, []);
    }
  }
  return userDisc;
};

export const getUploadPath = (projectId, objectId) => {
  return DIR_PROJECTS.concat(projectId ? projectId + '/' : '').concat(
    objectId ? objectId + '/' : DIR_TEMP
  );
};

export const deleteUnsavedAttachments = attachments => {
  if (attachments && attachments.length > 0)
    attachments.forEach(a => {
      if (!a.id) actionOnS3File(ACTION_DELETE, a.file);
    });
};

export const getFromDeletedUsers = (deletedUsers, user_id) => {
  const dUsers =
    deletedUsers && deletedUsers.filter(d => d.user_id === user_id);
  return dUsers && dUsers.length > 0 ? dUsers[0] : { name: '' };
};

export const getTopAccessLevel = roles => {
  if (roles && roles.length > 0) {
    const defaultTopRole = { name: ROLE_OBSERVER, access_level: 0 };
    const topRole = roles.reduce((topRole, role) => {
      return role.access_level > topRole.access_level ? role : topRole;
    }, defaultTopRole);
    return topRole.name;
  }

  return ROLE_OBSERVER;
};

const setUserParams = userList =>
  userList.map(u =>
    u.user
      ? u
      : {
          ...u,
          user: { id: u.id },
          organization_type: { id: u.organization.organization_type.id }
        }
  );
export const setRefUsers = params => {
  if (params.participants && params.participants.internal.length > 0)
    params.participants.internal = setUserParams(params.participants.internal);

  if (params.distribution && params.distribution.internal.length > 0)
    params.distribution.internal = setUserParams(params.distribution.internal);

  return params;
};

export const getOrgUsers = (
  organizations,
  recipientId,
  senderId,
  filter = false
) => {
  let users = [];
  if (organizations)
    users = organizations.reduce((acc, org) => {
      let userList =
        !filter || org.id === recipientId || org.id === senderId
          ? org.users.map(user => ({ ...user, organization: org }))
          : [];
      return acc.concat(userList);
    }, []);
  return users;
};

export const isDocObject = type => {
  if (type) {
    return (
      type.includes(OBJECT_DOCUMENTATION.toLowerCase()) ||
      type.toUpperCase() === OBJECT_DOC
    );
  } else return false;
};

export const convertLettersToNum = str => {
  var num = 0,
    len = str.length,
    pos = len;

  str = str.toUpperCase();
  while (--pos > -1) {
    num += (str.charCodeAt(pos) - 64) * Math.pow(26, len - pos - 1);
  }
  return num;
};

export const convertNumToLetters = num => {
  let letters = '',
    mod;

  while (num >= 0) {
    mod = num % 26;
    letters = String.fromCharCode(65 + mod) + letters;
    num = Math.floor(num / 26) - 1;
  }

  return letters;
};

export const getNextObjectRevision = revision => revision + 1;

export const getPreviousObjectRevision = revision => revision - 1;

export const convertRevision = revision =>
  isNaN(revision)
    ? convertLettersToNum(revision) - 1
    : convertNumToLetters(revision);

export const getRevisionDisplay = (
  revision,
  displayRevision,
  showLabel,
  hideFirstRev
) => {
  if (hideFirstRev && !revision) return '';
  return (showLabel ? REVISION_LABEL : '').concat(
    displayRevision || formatNumberDisplay(revision)
  );
};

export const getRevisionSequenceDisplay = (revisionType, revision = 0) => {
  switch (revisionType) {
    case 'Letter 00':
      if (revision === 0) return formatNumberDisplay(revision);
      revision--;
    // fallthrough
    case 'Letter':
      return convertNumToLetters(revision);
    case 'Number':
      revision++;
    // fallthrough
    default:
      return formatNumberDisplay(revision);
  }
};

const isSupportedLetter = value => {
  if (!value) return false;

  const regexAlpha = /^[A-Za-z]+$/;
  return regexAlpha.test(value);
};

const isValidRevision = (revisionType, revision) => {
  if (revision == null || revision === '') return false;
  if (revisionType === 'Letter 00' && revision === '0') return true;

  // Only the letters A-Z are allowed
  if (revisionType.includes('Letter') && !isSupportedLetter(revision))
    return false;

  const parsedRevision = parseInt(revision);
  if (
    (revisionType.includes('Number') && isNaN(parsedRevision)) || // Number logic but revision is not a number
    (revisionType.includes('Letter') && !isNaN(parsedRevision)) // Letter logic but revision is a number
  )
    return false;

  return true;
};

export const getRevisionInfo = (
  revisionType,
  revision,
  currentRevision = 0,
  previousRevision
) => {
  if (!isValidRevision(revisionType, revision))
    return { revision: null, revision_sequence_display: null }; // caller should handle error display/handling

  let updatedRevision = revisionType.includes('Letter')
    ? convertLettersToNum(revision)
    : parseInt(revision);

  // if revision type is not base 0, subtract 1 from processed revision #
  if (!revisionType.includes('00')) updatedRevision--;

  if (
    updatedRevision >= currentRevision ||
    (previousRevision !== null && updatedRevision > previousRevision)
  ) {
    const seqDisplay = getRevisionSequenceDisplay(
      revisionType,
      updatedRevision
    );
    return {
      revision: updatedRevision,
      revision_sequence_display: seqDisplay
    };
  } else return { revision: null, revision_sequence_display: null }; // caller should handle error display/handling
};

export const getUpdatedObjectFromRevisions = revisions => {
  const updatedObj = revisions.find(
    o => !!o.metadata.duplicate_revision_object_id
  );
  if (updatedObj)
    return revisions.find(
      r => r.id === updatedObj.metadata.duplicate_revision_object_id
    );
  else return null;
};

// Get the latest recipient field value:
// If the conflict occurs for the creator,
// that means that the recipient has responded to the latest revision;
// hence the recipient's response/comment should be copied and merged
// to the new revision from the creator (from conflict metadata).
// If the conflict occurs for the recipient,
// their latest response/comment should be copied and merged (from current form's metadata).
export const getRecipientFieldsToMerge = (metadata = {}, refName) => {
  switch (refName) {
    case OBJECT_BHM:
    case OBJECT_TA: {
      const { response, response_text } = metadata;
      return { response, response_text };
    }
    case OBJECT_EO:
    case OBJECT_KOE: {
      const { comment, comment_text } = metadata;
      return { comment, comment_text };
    }
    default:
      return {};
  }
};

export const getReloadParams = (
  objId,
  updatedObjFromConflict,
  vals,
  refName,
  isCreatorOrg,
  allStatuses,
  merge,
  previousSentStatus
) => {
  const status = getDefaultRevisionStatus(
    isCreatorOrg,
    allStatuses,
    previousSentStatus
  );
  let params = {
    updated_object_id: updatedObjFromConflict.id,
    id: objId,
    status_id: status.id
  };
  if (objId === updatedObjFromConflict.id) {
    params.updated_object_id =
      updatedObjFromConflict.metadata.next_version_object_id;
  }
  if (merge) {
    let orgMetadata = {};
    // increment revision for creator org
    if (isCreatorOrg)
      orgMetadata = {
        ...vals.metadata,
        ...getRecipientFieldsToMerge(updatedObjFromConflict.metadata, refName),
        prev_version_object_id: updatedObjFromConflict.id,
        revision: updatedObjFromConflict.metadata.revision + 1,
        revised_by_org_id: localStorage.getItem(LS_ORGANIZATION_ID)
      };
    else
      orgMetadata = {
        ...updatedObjFromConflict.metadata,
        ...getRecipientFieldsToMerge(vals.metadata, refName),
        revision: updatedObjFromConflict.metadata.revision,
        revised_by_org_id: updatedObjFromConflict.metadata.revised_by_org_id
      };
    params = {
      ...vals,
      metadata: { ...orgMetadata, next_version_object_id: null },
      merge: true,
      object_type_id: updatedObjFromConflict.object_type.id,
      project_id: sessionStorage.getItem(SS_STORED_PROJECT_ID),
      ...params
    };
  }
  return params;
};

export const getCategoryInfo = (categories, selected_category) => {
  const { ancestry_id, id } = selected_category;
  let categoryPath = { root: id, path: id };

  if (ancestry_id) {
    const parent = categories.find(cat => cat.id === ancestry_id);
    if (parent) {
      const path = getCategoryInfo(categories, parent);
      categoryPath = {
        root: path.root,
        path: `${path.path},`.concat(categoryPath.path)
      };
    }
  }
  return categoryPath;
};

export const getExistingCategoryNames = (category, documentCategories = []) =>
  category?.id
    ? category?.children?.map(child => child.name) || []
    : documentCategories.map(dc => dc.name);

// Utility functions for Meeting object
export const getMeetingNo = name => name?.split(HYPHEN)[2] || ELLIPSIS;

export const formatNumberDisplay = (number, targetLength = 2) =>
  number
    ?.toString()
    .replace(REGEX_ZERO, '')
    .padStart(targetLength, '0');

export const getAgendaItemNumber = (...indices) =>
  indices.reduce(
    (acc, index, ci) =>
      // check null since we are passing null if not sub-item
      index != null
        ? acc.concat(ci ? DOT : '').concat(formatNumberDisplay(index + 1))
        : acc,
    ''
  );

export const getNewAgenda = (index, itemNo) => ({
  agenda_id: '',
  rank: formatNumberDisplay(index),
  title: '',
  comment: [getNewCommentItem(1, itemNo, MAIN)],
  ctime: new Date().toISOString()
});

const getNewAgendaCommentsFromTemplate = comments =>
  comments.map(comment => {
    let new_comment = {
      ...comment,
      date: formatDate(new Date(), DEFAULT_DATE_FORMAT)
    };
    if (comment.sub_item)
      new_comment.sub_item = getNewAgendaCommentsFromTemplate(comment.sub_item);

    return new_comment;
  });

export const getNewAgendaFromTemplate = (agendum, itemNo) => ({
  ...agendum,
  agenda_id: '',
  comment: agendum.comment
    ? getNewAgendaCommentsFromTemplate(agendum.comment)
    : [getNewCommentItem(1, itemNo, MAIN)],
  ctime: new Date().toISOString()
});

export const getNewCommentItem = (index, itemNo, level) => {
  const item = {
    rank: formatNumberDisplay(index),
    action_owner: '',
    content: '',
    due_date: '',
    status: STATUS_OPEN,
    date: formatDate(new Date(), DEFAULT_DATE_FORMAT)
  };
  if (itemNo) item.item_no = itemNo;
  if (level === MAIN) item.sub_item = [];
  return item;
};

export const isSameMeeting = (itemNo, meetingNo, convertToInt = false) => {
  let meetingNoFromItem = itemNo?.split(DOT)[0];
  meetingNoFromItem = convertToInt
    ? parseInt(meetingNoFromItem)
    : meetingNoFromItem;

  return meetingNoFromItem === meetingNo;
};

export const parseItemNo = itemNo =>
  parseInt(itemNo.split(REGEX_LAST_DOT).pop());

export const getHighestItemNo = items =>
  items.reduce((num, item) => {
    const itemNo = item.item_no ? parseItemNo(item.item_no) : 0;
    return itemNo > num ? itemNo : num;
  }, 0);

export const getNextNoInMeeting = (agendaList = [], previous_count = 0) => {
  let hNo = agendaList.reduce((meetingHighestNo, agenda) => {
    const agendaHighestNo = getHighestItemNo(agenda.comment);
    return agendaHighestNo > meetingHighestNo
      ? agendaHighestNo
      : meetingHighestNo;
  }, 0);

  hNo = previous_count > hNo ? previous_count : hNo;
  return hNo + 1;
};

export const getNewDelmalebrev = () => {
  return {
    area: '',
    number_of_units: 0,
    status: '',
    sum: 0,
    locked: false // to match Orion and avoid issues for Reopen
  };
};

export const getEditorState = text => {
  return text
    ? EditorState.createWithContent(text)
    : createEditorStateWithText(text);
};

export const getEditorCurrentContent = text =>
  getEditorState(text).getCurrentContent();

export const formatForEditor = text => {
  if (text) {
    try {
      // if no error then it already conforms to the editor format
      JSON.parse(text);
    } catch (_err) {
      const editorState = EditorState.createWithContent(
        ContentState.createFromText(text)
      );
      text = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
    }
  }

  return text;
};

export const convertAndGetMentionedItems = content => {
  content = convertToRaw(content);

  return Object.values(content.entityMap).reduce(
    (acc, curr) => {
      const mention = curr.data.mention;
      if (!isObjectEmpty(mention)) {
        if (mention.type === USER) acc[USER].push(mention);
        // Older mentions do not have the type saved
        // but we are sure that they are OBJECT types since this was the first one supported
        // and type was only added when we started supporting USER.
        else acc[OBJECT].push(mention);
      }
      return acc;
    },
    { [OBJECT]: [], [USER]: [] }
  );
};

export const getMentionedObjId = obj =>
  obj.id || obj.link?.split(FORWARD_SLASH).pop();

export const showBoQApprovalStatus = (isCreatorOrg, status) => {
  return isCreatorOrg
    ? valueInArray(
        [
          STATUS_APPROVED_AND_CLOSED,
          STATUS_REJECTED,
          STATUS_REVISED,
          STATUS_PRELIM_BOQ_APPROVED
        ],
        status
      )
    : true;
};

export const getDisplayPostnr = objInfo => {
  const { chapter, post_nr, process } = objInfo;
  if (post_nr === process) return process;
  else return chapter.concat(chapter ? HYPHEN : '').concat(process);
};

export const processPdfDownloadParams = (
  currRevision,
  revisions,
  objectView,
  organizations,
  projectSelected
) => {
  // revisions is being copied(ES6) here, since we are using splice inside reduce
  const newHistories = [...revisions].reduce((acc, r, _i, remaining) => {
    // use 0 here since only some execution objects don't have revision
    const rev = r.metadata.revision || 0;
    const hist = r.history.reduce((acc, h) => {
      return h.status?.name === STATUS_DRAFT
        ? acc
        : acc.concat({ ...h, ctime: formatDate(h.ctime) });
    }, []);

    if (rev === currRevision) remaining.splice(1);
    return hist.length > 0
      ? {
          ...acc,
          [rev]: hist
        }
      : acc;
  }, {});

  let sortedDisciplines = {};

  const discs = sortDisciplinesByOrganization(
    objectView.metadata?.disciplines || {},
    projectSelected?.organization_hierarchy || []
  );
  discs.forEach(([orgName, discs]) => {
    sortedDisciplines = Object.assign(sortedDisciplines, {
      [orgName]: discs
    });
  });

  const org_users = valueInArray(
    [OBJECT_ICO.toLowerCase(), OBJECT_REF.toLowerCase()],
    objectView.object_type?.ref_name.toLowerCase()
  )
    ? getOrgUsers(organizations)
    : [];

  return { newHistories, sortedDisciplines, org_users };
};

export const processDownload = (attachment, callback) => {
  if (attachment.virus_scan === VIRUS_SCAN_CLEAN) {
    downloadS3File(attachment.file);
  } else {
    let defaultMessage = '',
      messageId = '',
      severity = '';
    if (attachment.virus_scan === VIRUS_SCAN_INFECTED) {
      defaultMessage =
        'Suspicious attachment warning – Be careful with this attachment.{br}' +
        'Our virus scan has marked this file as suspicious.{br}' +
        'Avoid downloading it unless you know the origin and are confident that the file is safe.';
      messageId = 'common.VIRUS_SCAN_DANGER';
      severity = ERROR;
    } else {
      defaultMessage =
        'The file has not yet been or could not be scanned for viruses.{br}' +
        'Are you sure you want to continue the download?';
      messageId = 'common.VIRUS_SCAN_WARNING';
      severity = WARNING;
    }
    const message = (
      <Box alignItems="center" display="block">
        <Alert severity={severity}>
          <FormattedMessage
            defaultMessage={defaultMessage}
            id={messageId}
            values={{ br: <br /> }}
          />
        </Alert>
      </Box>
    );

    callback({
      file: attachment.file,
      message: message
    });
  }
};

export const isOrgInDistribution = (list = [], orgId) =>
  list.find(entry => entry.organization.id === orgId);

export const processCommData = content =>
  content.map(obj => ({
    ...obj,
    first_published: obj.first_published
      ? formatDate(obj.first_published, DEFAULT_DATE_FORMAT)
      : '',
    display_status: obj.status ? (
      <FormattedMessage
        defaultMessage={obj.status.name}
        id={'object.' + obj.status.name.toUpperCase()}
        values={{ name: obj.organization?.name || '' }}
      />
    ) : (
      ''
    ),
    last_updated: obj.last_updated ? (
      <FormattedMessage
        defaultMessage={'{number} days'}
        id="common.DAYS"
        values={{ number: diffWithCurrDate(obj.last_updated) }}
      />
    ) : (
      ''
    )
  }));

export const processDocData = content =>
  content.map(obj => {
    // Doc distribution does not support guests (emails)
    // so we only need to process the internal list.
    let distribution = obj.distribution.internal.map(dist => ({
      organization: dist.organization
    }));

    // TODO: Remove once https://github.com/Vardoge/polaris/pull/801 is merged.
    if (obj.creator_organization)
      distribution.concat({ organization: obj.creator_organization });

    return {
      ...obj,
      display_name: getDisplayName(obj.name),
      display_status: obj.status ? (
        <FormattedMessage
          defaultMessage={obj.status.name}
          id={'object.' + obj.status.name.toUpperCase()}
          values={{ name: obj.organization?.name || '' }}
        />
      ) : (
        ''
      ),
      distribution,
      revision: getRevisionDisplay(
        obj.metadata?.revision,
        obj.metadata?.revision_sequence_display,
        false,
        true
      )
    };
  });
